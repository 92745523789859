import React from 'react';

import { Icons } from '../../../../../enums';
import { Icon } from '../../icon';

interface ModalHeaderProps {
  title?: string;
  onClose?: () => void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  onClose
}) => {
  return (
    <div className="popup-header">
      <div className="popup-header-title">
        <h1>
          { title }
        </h1>
      </div>
      <div
        onClick={onClose}
        className="popup-close-container"
      >
        <Icon type={Icons.Close} size='xs' />
      </div>
    </div>
  );
};
