import React, { useMemo } from 'react';

import { hexToRGBA } from '../../../../helpers';
import { noop } from '../../../../constants';
import { Icons } from '../../../../enums';

import './style.scss';

export type IconSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

interface IconProps {
  type: Icons;
  wrap?: boolean;
  size?: IconSize;
  colorHex?: string;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
}

// TODO: check icon sizes
export const Icon: React.FC<IconProps> = ({
  type,
  size = 'sm',
  wrap = false,
  colorHex = '',
  className = '',
  onClick = noop
}) => {

  const style = useMemo(() => {
    const result: React.CSSProperties = {
      color: colorHex,
    };
    if (wrap) {
      result.backgroundColor = hexToRGBA(colorHex || '#000000', 0.08)
    }
    return result;
  }, [colorHex, wrap]);

  return (
    <div
      style={style}
      onClick={onClick}
      className={`icon-wr ${className} ${size} ${wrap ? 'with-bg' : ''}`}
    >
      <i className={type} />
    </div>
  );
};
