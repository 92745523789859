import {Suspense, useEffect, useCallback} from 'react';
import {Route, Routes} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Sidebar} from './components/global';
import {appService} from '../services';
import {MainRoutes} from '../routing';
import {useStore} from '../hooks';
import {hasPermission} from '../helpers';
import {UserType} from '../enums';
import {getObjectKeys} from '../helpers/get-object-keys';
import Header from './components/global/header';

interface AppProps {

}

export const App: React.FC<AppProps> = observer(() => {

	const {
		appStore,
		partnerStore: {selectedBusiness},
		userSettingsStore: {userSettings: {data: {userType}}}
	} = useStore();
	const {appLoading} = appStore;

	useEffect(() => {
		appStore.setAppLoading(true)
		init();
	}, []);

	const init = useCallback(() => {
		appService.init().finally(() => {
			appStore.setAppLoading(false)
		})
	}, []);

	return (
		appLoading ? null : (
			<div className="main-wrapper">

				{/*todo NEW LIMIT SETTINGS POPUP START*/}

				{/*<div className="limitPopupWrapper">*/}
				{/*  <div className="limitPopupWrapperHolder">*/}
				{/*    <div className="limitPopupHeaderContainer">*/}
				{/*      <span className="limitPopupHeaderTxt">*/}
				{/*        Limit*/}
				{/*      </span>*/}
				{/*      <div className="popup-close-container">*/}
				{/*        <i className="icon-close"/>*/}
				{/*      </div>*/}
				{/*    </div>*/}
				{/*    <div className="limitPopupCurrencyHolder">*/}
				{/*      <div className="limitPopupCurrencyContainer">*/}
				{/*        <span className="limitPopupCurrencyTitles">*/}
				{/*          Main Currency*/}
				{/*        </span>*/}
				{/*        <span className="limitPopupCurrency">*/}
				{/*          EUR*/}
				{/*        </span>*/}
				{/*      </div>*/}
				{/*      <div className="limitPopupCurrencyContainer">*/}
				{/*        <span className="limitPopupCurrencyTitles">*/}
				{/*          Business Currency*/}
				{/*        </span>*/}
				{/*        <span className="limitPopupCurrency">*/}
				{/*          EUR*/}
				{/*        </span>*/}
				{/*      </div>*/}
				{/*    </div>*/}
				{/*    <div className="limitPopupCurrentLimitWrapper">*/}
				{/*      <div className="limitPopupCurrentLimitContainer">*/}
				{/*        <span className="limitPopupCurrencyTitles mediumOpacity">*/}
				{/*            Current Limit*/}
				{/*        </span>*/}
				{/*        <span className="limitPopupAmountTxt">*/}
				{/*            $ 500,000*/}
				{/*        </span>*/}
				{/*      </div>*/}
				{/*    </div>*/}
				{/*    <div className="limitPopupInputsWrapper">*/}
				{/*      <div className="limitPopupInputHolder inputBig">*/}
				{/*        <input type="text" className="limitPopupInput" placeholder="Amount"/>*/}
				{/*        <span className="limitPopupInputLabelHolder">*/}
				{/*          Amount*/}
				{/*        </span>*/}
				{/*      </div>*/}
				{/*      <div className="limitPopupInputHolder inputSmall">*/}
				{/*        <input type="text" className="limitPopupInput" placeholder="%"/>*/}
				{/*        <span className="limitPopupInputLabelHolder">*/}
				{/*          Percentage*/}
				{/*        </span>*/}
				{/*      </div>*/}
				{/*    </div>*/}
				{/*    <div className="limitPopupInputsWrapper">*/}
				{/*      <div className="limitPopupInputHolder">*/}
				{/*        <input type="text" className="limitPopupInput" placeholder="Limit"/>*/}
				{/*        <span className="limitPopupInputLabelHolder">*/}
				{/*          Limit*/}
				{/*        </span>*/}
				{/*      </div>*/}
				{/*    </div>*/}
				{/*    <div className="limitPopupCurrentLimitWrapper">*/}
				{/*      <div className="limitPopupCurrentLimitContainer">*/}
				{/*        <span className="limitPopupCurrencyTitles mediumOpacity">*/}
				{/*            Converted Limit*/}
				{/*        </span>*/}
				{/*        <span className="limitPopupAmountTxt">*/}
				{/*            $ 700,000*/}
				{/*        </span>*/}
				{/*      </div>*/}
				{/*    </div>*/}
				{/*    <div className="limitPopupBtnWrapper">*/}
				{/*      <div className="limitPopupBtnHolder">*/}
				{/*        <button type="button" className="button default primary">*/}
				{/*          <span className="button-txt">Porvide Limit</span>*/}
				{/*        </button>*/}
				{/*      </div>*/}
				{/*    </div>*/}
				{/*  </div>*/}
				{/*</div>*/}

				{/*todo NEW LIMIT SETTINGS POPUP END*/}


				<Sidebar businessId={selectedBusiness?.id}
								 disableMenuList={!getObjectKeys(selectedBusiness)?.length && hasPermission(userType as UserType, [UserType.Partner])}/>
				<div className="pages-holder">
					<Header/>
					<div className="page-content scrollable-container vertical">
						<Suspense fallback="">
							<Routes>
								{
									MainRoutes.map((route) => (
										<Route
											key={route.path}
											path={route.path}
											element={<route.component/>}
										>
											{
												route.nestedRoutes?.map((nestedRote) => (
													<Route
														key={nestedRote.path}
														path={nestedRote.path}
														element={<nestedRote.component/>}
													/>
												))
											}
										</Route>
									))
								}
							</Routes>
						</Suspense>
					</div>
				</div>
			</div>
		)
	);
});
