import { T } from './translation-keys';
import {BetStatus, TicketStatus} from '../enums';

export const BetStatusNames = {
  [BetStatus.Lose]: T.LOSE,
  [BetStatus.Win]: T.WIN,
  [BetStatus.Pending]: T.PENDING,
  [BetStatus.Canceled]: T.CANCELED,
  [BetStatus.Unknown]: T.UNKNOWN,
  [BetStatus.Calculation]: T.CALCULATION,
  [BetStatus.OnCancel]: T.ON_CANCEL,
  [BetStatus.Returned]: T.RETURNED,
}

export const BetStatusClassNames = {
  [BetStatus.Lose]: '#e84855',
  [BetStatus.Win]: '#4caf50',
  [BetStatus.Pending]: '#ff8c00',
  [BetStatus.Canceled]: 'transparent',
  [BetStatus.Unknown]: '',
  [BetStatus.Calculation]: '',
  [BetStatus.OnCancel]: '',
  [BetStatus.Returned]: '#7d33db',
}