import { action, makeObservable, observable } from 'mobx';

import { RootStore } from './index';

export class AppStore {

  rootStore: RootStore;

  public splashLoading = true;
  public appLoading = true;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      splashLoading: observable,
      appLoading: observable,
      setAppLoading: action.bound,
      setSplashLoading: action.bound,
    })
  }

  public setSplashLoading(value: boolean): void {
    this.splashLoading = value
  }

  public setAppLoading(value: boolean): void {
    this.appLoading = value
  }

}