import {memo, useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {PartnerBusiness, PartnerBusinessResponse} from '../../../../../models';

import {DropdownOptionModel} from '../../../shared/dropdown/models';

import {dataToDropDownConverter, LocalStorageHelper} from '../../../../../helpers';

import {useForm, useOutsideClick, useStore, useTranslation} from '../../../../../hooks';

import BusinessItem from './business-item';
import CreateBusinessModal from './create-business-modal';
import {isValid} from '../../../../../helpers/form-validation';
import {getObjectKeys} from '../../../../../helpers/get-object-keys';
import {LocalStorageKeys, RegexConstants, T} from '../../../../../constants';
import './styles.scss';
import {Business} from '../../../../../models/api/business-settings';

interface MultiCurrencyProps {
	businessData: PartnerBusinessResponse,
	currenciesDropdownData: Array<DropdownOptionModel>,
	partnerId?: number,
}

const CreateBusiness: React.FC<MultiCurrencyProps> = observer(({
																																 businessData,
																																 currenciesDropdownData,
																																 partnerId
																															 }) => {
	const t = useTranslation();

	const {partnerStore, countriesStore, businessStore: {addBusiness}} = useStore();
	const {setSelectedBusiness, selectedBusiness} = partnerStore;
	const {countries: {data: {count: countOfCountries, items: itemOfCountries}}} = countriesStore;

	const {count, items} = businessData || {};

	const [openBusinessList, setOpenBusinessList] = useState(false);
	const [openCreateBusinessModal, setOpenCreateBusinessModal] = useState(false)

	const businessListRef = useRef<HTMLDivElement>(null);

	const {
		values,
		setValues,
		resetForm,
	} = useForm({
		name: '',
		partnerId: null,
		currencyCode: null,
		countryId: null,
		isPinRequiredForBalanceChange: true,
		forbidPaymentFromOtherBetshop: false,
		isBalanceOperationDisabled: false
	});
	const [errorMessage, setErrorMessage] = useState<string>('')

	useEffect(() => {
		if (!openCreateBusinessModal) {
			setErrorMessage('')
			resetForm()
		}
	}, [openCreateBusinessModal])

	const handleChange = (value: any, key: string): void => {
		setValues((values: any) => ({...values, [key]: value}));
	};

	const handleCancel = () => {
		setOpenCreateBusinessModal(false);
	};

	const handleCreateBusiness = () => {
		const errorMessage = isValid(values.name, RegexConstants.BusinessBetShopName, T.BUSINESS_BETSHOP_NAME_VALIDATION);
		setErrorMessage(errorMessage);
		if (errorMessage) return
		addBusiness(values as Business).then(() => {
			partnerStore.getPartnerBusiness();
			setOpenCreateBusinessModal(false);
		})
	};

	const createBusiness = () => {
		setOpenCreateBusinessModal(!openCreateBusinessModal);
		if (!countOfCountries) {
			countriesStore.getCountries();
		}
	}

	const countriesList = useMemo<Array<DropdownOptionModel>>(() => {
		return dataToDropDownConverter(itemOfCountries, {valueKey: 'id', labelKey: 'name'});
	}, [itemOfCountries])

	useOutsideClick(() => setOpenBusinessList(false), businessListRef);

	return <>
		{
			!count ?
				<div className="multi-currency-btn-holder" onClick={createBusiness}>
					<button className="button default primary" type="button">
						<span className="button-txt">{t(T.CREATE_BUSINESS)}</span>
					</button>
				</div> :
				<div className={`multi-currency-dropdown-holder ${openBusinessList ? 'active' : ''}`}>
					{/*todo add active class to multi-currency-dropdown-country-wrapper in open dropDown state*/}
					<div className="multi-currency-dropdown-country-wrapper" ref={businessListRef}
							 onClick={() => setOpenBusinessList(!openBusinessList)}>
						<div className="multi-currency-dropdown-country-holder">
								<span
									className="multi-currency-dropdown-country-header outer-header">{partnerId && LocalStorageHelper.getItem(LocalStorageKeys.SelectedBusiness)?.[partnerId] ? selectedBusiness?.name : t(T.CHOOSE_BUSINESS)} </span>
							{getObjectKeys(selectedBusiness).length ? <span
								className="multi-currency-dropdown-country-title outer-title">{selectedBusiness?.currencyCode}</span> : null}
						</div>
						<div className="dropdown-icon">
							<i className="icon-arrow-down"/>
						</div>
					</div>
					<div className="multi-currency-dropdown-inner-holder">
						<div className="multi-currency-dropdown-content">
							<div className="multi-currency-dropdown-content-inside">
								<div className="multi-currency-dropdown-inner-container">
									{/*<div className="businessOverviewWrapper">*/}
									{/*		<span className="businessOverviewTxt">*/}
									{/*			My Businesses*/}
									{/*		</span>*/}
									{/*		<button type="button" className="button defaultSmall secondary">*/}
									{/*			<span className="button-txt">Overview</span>*/}
									{/*		</button>*/}
									{/*</div>*/}
									<div className="multi-currency-dropdown-items">
										{
											items?.map(businessItem => (
												<BusinessItem data={businessItem} setSelectedBusiness={setSelectedBusiness}
																			isSelected={businessItem.id === selectedBusiness?.id}/>
											))
										}
									</div>
									<div className="multi-currency-dropdown-inner-holder-column-2"
											 onClick={createBusiness}>
										<button type="button" className="button default light-secondary  justif-end">
											<div className="icon-wr  xs ">
												<i className="icon-plus"/>
											</div>
											<span className="button-txt">{t(T.CREATE_BUSINESS)}</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
		}
		{openCreateBusinessModal ?
			<CreateBusinessModal setOpenCreateBusinessModal={handleCancel} values={values as PartnerBusiness}
													 handleChange={handleChange} currenciesDropdownData={currenciesDropdownData}
													 handleCreateBusiness={handleCreateBusiness} handleCancel={handleCancel}
													 countriesList={countriesList} errorMessage={errorMessage}/> : null
		}
	</>
})

export default memo(CreateBusiness)
