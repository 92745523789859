import {action, makeObservable, observable} from 'mobx';

import {LimitOrBalanceUpdateData, WalletResponse} from '../models';
import {HttpResponse, walletController} from '../api';
import {RootStore} from './index';

export class WalletStore {

	private rootStore: RootStore;

	public wallet = new HttpResponse<Partial<WalletResponse>>({});

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeObservable(this, {
			wallet: observable,
			setWallet: action.bound,
		});
	}

	public setWallet(value: HttpResponse<Partial<WalletResponse>>): void {
		this.wallet = value;
	}

	public async getWallet(userId: number): Promise<void> {
		this.setWallet(this.wallet.fetching());
		const data = await walletController.getWallet(userId);
		this.setWallet(this.wallet.fetched(data));
	}

	public async updateLimit(id: number, data: LimitOrBalanceUpdateData, isWithDraw: boolean) {
		return isWithDraw ? walletController.removeLimit(id, data) : walletController.updateLimit(id, data);
	}

	public async updateBalance(id: number, data: LimitOrBalanceUpdateData, isWithDraw: boolean) {
		return isWithDraw ? walletController.removeBalance(id, data) : walletController.updateBalance(id, data);
	}

}