import React from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import { RoutePaths } from '../../../constants';
import { useStore } from '../../../hooks';

interface IsAuthLayerProps {
  children: React.ReactNode;
}

export const IsAuthLayer: React.FC<IsAuthLayerProps> = observer(({ children }) => {

  const { authStore: { isAuth } } = useStore();

  return (
    <>
      {
        isAuth ? children : <Navigate to={RoutePaths.Login} />
      }
    </>
  );
});
