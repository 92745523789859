import axios, { AxiosError, AxiosResponse } from 'axios';

import { DEFAULT_ERROR_RESPONSE } from '../constants';
import { HTTPResponse, QueryModel } from './../models';
import { combineQuery } from '../helpers';

export const http = axios.create({
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    // 'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
  },
  paramsSerializer: {
    serialize: (params: QueryModel) => {
      return combineQuery(params).slice(1);
    }
  }
});


// TODO need create dynamic http axios function
export const httpForFormData = axios.create({
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'multipart/form-data'
  },
  paramsSerializer: {
    serialize: (params: QueryModel) => {
      return combineQuery(params).slice(1);
    }
  }
});

const onFulfilled = (res: AxiosResponse<HTTPResponse<any>>) => res.data.result;

const onRejected = (error: AxiosError) => {
  throw error.response?.data || DEFAULT_ERROR_RESPONSE;
};

http.interceptors.response.use(onFulfilled, onRejected);
