import React from 'react';

interface TableCellProps {
  children: React.ReactNode;
}

export const TableCell: React.FC<TableCellProps> = ({ children }) => {
  return (
    <div className="table-col">
      { children }
    </div>
  );
};
