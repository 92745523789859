import React from 'react';

import { useTranslation } from '../../../../../hooks';
import { WEEK_DAYS } from '../../../../../constants';

interface WeeksProps {

}

export const Weeks: React.FC<WeeksProps> = () => {

  const t = useTranslation();

  return (
    <div className="calendar-days-row days-heading">
      {
        WEEK_DAYS.map((week) => (
          <div
            key={week}
            className="calendar-days-col"
          >
            <p className="calendar-day">
              { t(week) }
            </p>
          </div>
        ))
      }
    </div>
  );
};
