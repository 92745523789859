import { action, makeObservable, observable } from "mobx";

import { HttpResponse } from "../api";
import { RootStore } from "./index";
import {
  betShopRequest,
  betShops,
  betShopsResponse,
  BetShopsResponse,
  GameResponse,
  GamesControlRequest,
  GamesControlResponse,
} from "../models/api/games-control";
import { gamesControlController } from "../api/controllers/games-control";
import { DEFAULT_RESPONSE_LIST } from "../constants";
import { Betshop, Game } from "../models";

export class GamesControlStore {
  private rootStore: RootStore;

  public gameList = new HttpResponse<GamesControlResponse>(
    DEFAULT_RESPONSE_LIST,
  );

  public betShopGameList = new HttpResponse<GameResponse>(
    DEFAULT_RESPONSE_LIST,
  );
  public betShopList = new HttpResponse<BetShopsResponse>(
    DEFAULT_RESPONSE_LIST,
  );

  public gameById = new HttpResponse<Partial<Game>>({});

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      gameList: observable,
      gameById: observable,
      betShopList: observable,
      betShopGameList: observable,
      setGameById: action.bound,
      setGamesList: action.bound,
      setBetShopsList: action.bound,
      setBetShopGamesList: action.bound,
    });
  }

  public setGamesList(value: HttpResponse<GamesControlResponse>): void {
    this.gameList = value;
  }

  public setBetShopsList(value: HttpResponse<BetShopsResponse>): void {
    this.betShopList = value;
  }
  public setBetShopGamesList(value: HttpResponse<GameResponse>): void {
    this.betShopGameList = value;
  }

  public setGameById(value: HttpResponse<Partial<Game>>): void {
    this.gameById = value;
  }

  public async getGamesList(params?: GamesControlRequest): Promise<void> {
    this.setGamesList(this.gameList.fetching());
    const data = await gamesControlController.getGameList(params);
    this.setGamesList(this.gameList.fetched(data));
  }

  public async getGameById(id: number): Promise<void> {
    this.setGameById(this.gameById.fetching());
    const data = await gamesControlController.getGameById(id);
    this.setGameById(this.gameById.fetched(data));
  }

  public async getBetShops(params: betShopRequest): Promise<void> {
    this.setBetShopsList(this.betShopList.fetching());
    const data = await gamesControlController.getBetShops(params);
    this.setBetShopsList(this.betShopList.fetched(data));
  }

  public async updateGroupType(gameId: number, type: number): Promise<void> {
    await gamesControlController.updateGroupType(gameId, type);
  }

  public async getBetShopGames(params: betShopRequest): Promise<void> {
    this.setBetShopGamesList(this.betShopGameList.fetching());
    const data = await gamesControlController.getBetShopsGames(params);
    this.setBetShopGamesList(this.betShopGameList.fetched(data));
  }

  public async resetBetShopList() {
    this.setBetShopsList(this.betShopList.fetched(DEFAULT_RESPONSE_LIST));
  }

  public async updateBetShops(data: betShopsResponse): Promise<void> {
    await gamesControlController.updateBetShops(data);
  }
}
