import './style.scss';

interface ActionProps {
  onclick?: () => void;
  children: React.ReactNode;
}

export const Action: React.FC<ActionProps> = ({
  onclick,
  children,
}) => {
  return (
    <div
      onClick={onclick}
      className='action'
    >
      { children }
    </div>
  )
};
