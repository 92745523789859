export const getHoursList = () => {
	const xH = [];
	for (let i = 0; i < 24; i++) {
		xH.push(i < 10 ? '0' + i : i.toString())
	}
	return xH
}

export const getMinutesList = () => {
	const xH = [];
	for (let i = 0; i < 60; i++) {
		xH.push(i < 10 ? '0' + i : i.toString())
	}
	return xH
}

export const getHours = (currentH: string, action: string) => {
	const hoursList = getHoursList()
	const getCurrentHIndex = hoursList.indexOf(currentH);
	return action === 'minus' ? hoursList[getCurrentHIndex - 1] || hoursList[hoursList.length - 1] : hoursList[getCurrentHIndex + 1] || hoursList[0]

}

export const getMinute = (currentM: string, action: string) => {
	const minuteList = getMinutesList()
	const getCurrentMIndex = minuteList.indexOf(currentM);
	return action === 'minus' ? minuteList[getCurrentMIndex - 1] || minuteList[minuteList.length - 1] : minuteList[getCurrentMIndex + 1] || minuteList[0]

}