import React, { useCallback } from 'react';

import { Notification } from '../../../../../models';
import { Icons } from '../../../../../enums';
import { Icon } from '../../icon';

import './style.scss';
import {useTranslation} from '../../../../../hooks';

interface NotificationItemProps extends Notification {
  onClose: (id: string) => void;
  duration: number;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
  id,
  icon,
  message,
  onClose,
  colorHex,
  duration
}) => {
const t = useTranslation();
  const handleCloseClick = useCallback(() => {
    onClose(id);
  }, [onClose, id]);

  return (
    <div className="toast-message">
      <div className="toast-message-inside">
        <div className="toast-message-content">
          <div className="toast-message-info">
            <Icon
              wrap
              type={icon}
              colorHex={colorHex}
              className="toast-message-icon"
            />
            {/* TODO: translate messages */}
            <p className="toast-message-txt" title={t(message)}>{t(message)}</p>
          </div>
          <div className="toast-message-actions">
            <Icon
              size='xs'
              colorHex="#797a7b"
              type={Icons.Close}
              onClick={handleCloseClick}
              className="toast-message-action close"
            />
          </div>
        </div>
        <div
          className="toast-message-loader"
          style={{
            backgroundColor: colorHex,
            animationDuration: `${duration}ms`
          }}
        />
      </div>
    </div>
  );
};
