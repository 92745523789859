import {
  GetTicketsRequest,
  TicketByIdResponse,
  TicketsResponse,
} from '../../../models';
import { BaseApiService } from '../../base-api-service';
import { ApiVersions } from '../../../enums';
import { env } from '../../../environment';

class TicketsController extends BaseApiService {

  public async getTickets(params: GetTicketsRequest ): Promise<TicketsResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/ticket/history`;
    return await this.http.get(url, { params });
  }

  public async getTicketById(id: number): Promise<TicketByIdResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/ticket/${id}`;
    return await this.http.get(url);
  }

}

export const ticketsController = new TicketsController({ url: env.httpUrl });
