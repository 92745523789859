import { Button, Loader } from "../../../components/shared";
import React, { useEffect, useMemo, useState } from "react";
import { checkValidations } from "../../../../helpers/form-validation";
import { RegexConstants, RoutePaths, T } from "../../../../constants";
import { useQuery, useStore, useTranslation } from "../../../../hooks";
import { Icons } from "../../../../enums";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Toaster } from "../../../containers";
import Input from "../../../components/shared/input";

interface NewPasswordProps {
  changePasswordForAuthUser?: boolean;
  userId?: number;
}

export const NewPassword: React.FC<NewPasswordProps> = observer(
  ({ changePasswordForAuthUser, userId }) => {
    const t = useTranslation();
    const { queryString, query, pathname } = useQuery();
    const { usersStore } = useStore();
    const navigate = useNavigate();

    const [passwordValue, setPasswordValue] = useState("");
    const [oldPasswordValue, setOldPasswordValue] = useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [isReady, setIsReady] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordChangedSuccessfully, setPasswordChangedSuccessfully] =
      useState(false);

    useEffect(() => {
      if (!passwordValue && !confirmPasswordValue) {
        setErrorMessage("");
      }
    }, [passwordValue, confirmPasswordValue]);

    useEffect(() => {
      if (query.token && pathname === "new-password") {
        usersStore
          .confirmEmail(query.token, query.userId)
          .then((res) => {
            setIsReady(true);
          })
          .catch(({ error }) => {
            console.log(error);
            Toaster.error({ message: error?.errorMessage });
          });
      }
    }, [queryString]);

    const validations = useMemo(() => {
      return [
        {
          text: T.AT_LEAST_8_CHARACTERS,
          isValid: checkValidations(
            RegexConstants.CharactersLength,
            passwordValue,
          ),
        },
        {
          text: T.UPPERCASE_AND_LOWERCASE_CHARACTER,
          isValid: checkValidations(
            RegexConstants.UppercaseAndLowerCase,
            passwordValue,
          ),
        },
        {
          text: T.ONE_SPECIAL_CHARACTER,
          isValid: checkValidations(
            RegexConstants.SpecialCharacter,
            passwordValue,
          ),
        },
        {
          text: T.ONE_NUMBER,
          isValid: checkValidations(RegexConstants.MinOneNumber, passwordValue),
        },
      ];
    }, [passwordValue]);

    const showHidePassIcon = (value: string, condition: boolean) => {
      return value.length
        ? condition
          ? Icons.VisibilityOn
          : Icons.VisibilityOff
        : null;
    };

    const updatePassword = () => {
      if (passwordChangedSuccessfully) {
        navigate(RoutePaths.Login);
        return;
      }

      if (confirmPasswordValue !== passwordValue) {
        setErrorMessage(T.PASSWORD_DOSE_NOT_MATCH);
        return;
      }

      if (userId || query.userId) {
        if (query.token && pathname === "reset-password") {
          usersStore
            .resetPassword(passwordValue, query.token, query.userId)
            .then(() => {
              setPasswordChangedSuccessfully(true);
            })
            .catch(({ errorMessage }) => {
              setErrorMessage(t(errorMessage));
            });
        } else {
          const data = {
            userId: userId || query.userId,
            currentPassword: oldPasswordValue,
            newPassword: passwordValue,
            confirmPassword: confirmPasswordValue,
          };

          usersStore
            .changePassword(data)
            .then(() => {
              if (changePasswordForAuthUser) {
                navigate(RoutePaths.Dashboard);
                Toaster.success({
                  message: t(T.PASSWORD_CHANGED_SUCCESSFULLY),
                });
              } else {
                setPasswordChangedSuccessfully(true);
              }
            })
            .catch(({ errorMessage }) => {
              setErrorMessage(t(errorMessage));
            });
        }
      }
    };

    const updatePassWithEnter = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        if (!passwordValue) return;
        updatePassword();
      }
    };

    return (
      <div className="new-password-block">
        <div className="new-password-wrp">
          {!isReady ? (
            <Loader />
          ) : (
            <>
              {!changePasswordForAuthUser ? (
                <div className="gravity-logo" />
              ) : null}

              <div className="new-password-holder">
                {!passwordChangedSuccessfully ? (
                  <div className="reset-password-form">
                    <p className="sign-in-form-header">
                      {pathname === "new-password"
                        ? t(T.SET_NEW_PASSWORD)
                        : t(T.RESET_PASSWORD)}
                    </p>
                    {pathname === "new-password" ||
                    changePasswordForAuthUser ? (
                      <div className="new-pass-inp-row">
                        <Input
                          value={oldPasswordValue}
                          onChange={(e) => setOldPasswordValue(e.target.value)}
                          type={
                            showOldPassword && oldPasswordValue
                              ? "text"
                              : "password"
                          }
                          icon={showHidePassIcon(
                            oldPasswordValue,
                            showOldPassword,
                          )}
                          onIconClick={() =>
                            setShowOldPassword(!showOldPassword)
                          }
                          onKeyPress={updatePassWithEnter}
                          placeholder={t(T.OLD_PASSWORD)}
                          label={t(T.OLD_PASSWORD)}
                        />
                      </div>
                    ) : null}
                    <div className="new-pass-inp-row">
                      <Input
                        value={passwordValue}
                        onChange={(e) => setPasswordValue(e.target.value)}
                        type={
                          showPassword && passwordValue ? "text" : "password"
                        }
                        icon={showHidePassIcon(passwordValue, showPassword)}
                        onIconClick={() => setShowPassword(!showPassword)}
                        onKeyPress={updatePassWithEnter}
                        placeholder={t(T.NEW_PASSWORD)}
                        label={t(T.NEW_PASSWORD)}
                      />
                    </div>
                    <div className="new-pass-inp-row">
                      <Input
                        value={confirmPasswordValue}
                        onChange={(e) =>
                          setConfirmPasswordValue(e.target.value)
                        }
                        type={
                          showConfirmPassword && confirmPasswordValue
                            ? "text"
                            : "password"
                        }
                        onIconClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onKeyPress={updatePassWithEnter}
                        errorMessage={errorMessage}
                        dontHighlightError
                        icon={showHidePassIcon(
                          confirmPasswordValue,
                          showConfirmPassword,
                        )}
                        placeholder={t(T.CONFIRM_PASSWORD)}
                        label={t(T.CONFIRM_PASSWORD)}
                      />
                    </div>

                    <div className="new-pass-tooltip-block">
                      <div className="input-tooltip-inner">
                        {validations.map((item) => (
                          <div
                            className={`input-tooltip-row ${item.isValid ? "active" : ""}`}
                          >
                            <span className="input-tooltip-row-info">
                              {t(item.text)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="email-sent-success-block">
                    <p className="email-sent-success-txt">
                      {t(T.PASSWORD_CHANGED)}
                    </p>
                    <div className="email-sent-success-icon-block">
                      <i className="icon-check-circle" />
                    </div>
                  </div>
                )}

                <Button
                  text={t(passwordChangedSuccessfully ? T.SIGN_IN : T.UPDATE)}
                  onClick={updatePassword}
                  disabled={
                    (!validations.every((item) => item.isValid) ||
                      !confirmPasswordValue) &&
                    !passwordChangedSuccessfully
                  }
                  icon={passwordChangedSuccessfully ? Icons.ArrowRight : null}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  },
);
