import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";
import {
  betShopRequest,
  betShops,
  betShopsResponse,
  BetShopsResponse,
  GameResponse,
  GamesControlRequest,
  GamesControlResponse,
} from "../../../models/api/games-control";
import { Betshop, Game } from "../../../models";

class GamesControlController extends BaseApiService {
  public async getGameList(
    params?: GamesControlRequest,
  ): Promise<GamesControlResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/game`;
    return await this.http.get(url, { params });
  }

  public async getGameById(id: number): Promise<Game> {
    const url = `${this.url}/api/${ApiVersions.V1}/game/${id}`;
    return await this.http.get(url);
  }

  public async updateGroupType(gameId: number, type: number): Promise<any> {
    const url = `${this.url}/api/${ApiVersions.V1}/game/${gameId}`;
    return await this.http.put(url, { type });
  }

  public async updateBetShops(
    data: betShopsResponse,
  ): Promise<BetShopsResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/betshopGame`;
    return await this.http.post(url, data);
  }

  public async getBetShopsGames(params: betShopRequest): Promise<GameResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/betshopGame`;
    return await this.http.get(url, { params });
  }

  public async getBetShops(params: betShopRequest): Promise<BetShopsResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/betshopGame/betshops`;
    return await this.http.get(url, { params });
  }
}

export const gamesControlController = new GamesControlController({
  url: env.httpUrl,
});
