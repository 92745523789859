import {AddUserData, changePassword, GetUserRequest, User, UsersResponse} from '../../../models';
import {BaseApiService} from '../../base-api-service';
import {ApiVersions} from '../../../enums';
import {env} from '../../../environment';

class UsersController extends BaseApiService {

	public async getUsers(params?: GetUserRequest): Promise<UsersResponse> {
		const url = `${this.url}/api/${ApiVersions.V1}/user`;
		return await this.http.get(url, {params});
	}

	public async getUserById(id: number): Promise<User> {
		const url = `${this.url}/api/${ApiVersions.V1}/user/${id}`;
		return await this.http.get(url);
	}

	public async addUser(data: AddUserData): Promise<void> {
		const url = `${this.url}/api/${ApiVersions.V1}/user`;
		return await this.http.post(url, data);
	}

	public async blockUser(id: number): Promise<void> {
		const url = `${this.url}/api/${ApiVersions.V1}/user/block/${id}`;
		return await this.http.put(url);
	}

	public async unblockUser(id: number): Promise<void> {
		const url = `${this.url}/api/${ApiVersions.V1}/user/unblock/${id}`;
		return await this.http.put(url);
	}

	public async resetPin(id: number): Promise<void> {
		const url = `${this.url}/api/${ApiVersions.V1}/user/pin-reset/${id}`;
		return await this.http.put(url);
	}

	public async sendResetPasswordEmail(email: string): Promise<void> {
		const url = `${this.url}/api/${ApiVersions.V1}/user/sendResetPasswordEmail`;
		return await this.http.post(url, {email});
	}

	public async resetPassword(newPassword: string, token: string, userId: string): Promise<void> {
		const url = `${this.url}/api/${ApiVersions.V1}/user/resetPassword`;
		return await this.http.post(url, {newPassword, token, userId});
	}
	public async confirmEmail(token: string, userId: string): Promise<void> {
		const url = `${this.url}/api/${ApiVersions.V1}/user/${userId}/confirmEmail`;
		return await this.http.post(url, {token});
	}

	public async changePassword(params: changePassword): Promise<void> {
		const url = `${this.url}/api/${ApiVersions.V1}/user/changePassword`;
		return await this.http.put(url, params);
	}
}

export const usersController = new UsersController({url: env.httpUrl});
