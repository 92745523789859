import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { getCurrentLanguageCode } from '../../../helpers';
import { Loader } from '../../components/shared';
import { useStore } from '../../../hooks';

interface SplashProps {
  children: React.ReactNode;
}

export const Splash: React.FC<SplashProps> = observer(({ children }) => {

  const { authStore, translationsStore, appStore } = useStore();
  const { splashLoading, appLoading } = appStore;
  const { isAuth } = authStore;

  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = useCallback(async () => {
    if (isAuth) {
      await authStore.refresh();
    } else {
      await translationsStore.getTranslations({ code: getCurrentLanguageCode() });
    }
    appStore.setSplashLoading(false);
    appStore.setAppLoading(false);
  }, [isAuth]);

  return (
    <>
      { splashLoading ? null  : children }
      { (splashLoading || appLoading) ? <Loader /> : null }
    </>
  );
});
