import React, {useCallback} from 'react';

import {PaginationSelectorsData} from '../../../../../constants';
import {Dropdown} from '../../dropdown';
import {Pagination} from './pagination';
import {RowsCount} from './rows-count';

interface TableFooterProps {
	rowsCount: number;
	pagesCount: number;
	currentPage: number;
	selectorValue: number;
	pageRowsCount: number;
	onPaginationChange: (page: number, selectorValue: number) => void;
}

export const TableFooter: React.FC<TableFooterProps> = ({
																													rowsCount,
																													pagesCount,
																													currentPage,
																													pageRowsCount,
																													selectorValue,
																													onPaginationChange,
																												}) => {

	const handleSelectorValueChange = useCallback((value: number) => {
		if (+selectorValue === value) return;
		onPaginationChange(1, +value);
	}, [selectorValue, onPaginationChange]);

	const skip = (currentPage - 1) * selectorValue;

	const getPaginationCount = () => {
		const currentData = [...PaginationSelectorsData]
		const index = currentData.findIndex(item => rowsCount <= item.value)

		return index > -1 ? currentData.slice(0,  index + 1) : PaginationSelectorsData;
	}

	return (
		<div className="table-footer">
			<div className="table-content-count">
				<div className="table-dropdown">
					<Dropdown
						size="sm"
						direction="to-top"
						value={selectorValue}
						data={getPaginationCount()}
						onChange={(value) => handleSelectorValueChange(value as number)}
					/>
				</div>
				<RowsCount
					skip={skip}
					rowsCount={rowsCount}
					pageRowsCount={pageRowsCount}
				/>
			</div>
			<Pagination
				count={pagesCount}
				currentPage={currentPage}
				selectorValue={selectorValue}
				onPaginationChange={onPaginationChange}
			/>
		</div>
	);
};
