import {PartnerBusiness} from '../../../../../../models';
import {Currency} from '../../../../shared';

interface BusinessItemProps {
	setSelectedBusiness: (data: PartnerBusiness) => void,
	data: PartnerBusiness,
	isSelected: boolean
}

const BusinessItem: React.FC<BusinessItemProps> = ({data, setSelectedBusiness, isSelected}) => {
	return <div className="padding-holder" key={data.id}
							onClick={() => setSelectedBusiness(data)}>
		<div className={`multi-currency-dropdown-inner-holder-column-1 ${isSelected ? 'active' : ''}`}>
			<div className="multi-currency-dropdown-country-holder">
				<span
					className="multi-currency-dropdown-country-header inner-header">{data.name}</span>
				<span
					className="multi-currency-dropdown-country-title inner-title">{data.currencyCode}</span>
			</div>
			<div className="multi-currency-dropdown-inner-currency-holder">
				<span className="multi-currency-dropdown-inner-currency-txt"><Currency
					value={data.limit}
					convert={false}
					currencyCode={data.currencyCode}
				/></span>
			</div>
		</div>
	</div>
}

export default BusinessItem