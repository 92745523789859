import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { ModalContainer, Providers, Splash } from "./containers";
import { Toaster } from "./containers/toaster";
import { AuthRoutes } from "../routing";
import { getCurrentTheme } from "../helpers/get-current-theme";
import { IS_UNIVERSAL_PARTNER } from "../constants/host-name";

interface AppWrapperProps {}

export const AppWrapper: React.FC<AppWrapperProps> = () => {
  useEffect(() => {
    document.documentElement.setAttribute("class", getCurrentTheme());

    let favicon = document.querySelector(
      'link[rel="icon"]',
    ) as HTMLLinkElement | null;

    if (IS_UNIVERSAL_PARTNER) {
      document.title = "Virtuales Pro";
      if (favicon) {
        favicon.href = `${process.env.PUBLIC_URL}/universalSoftLogo.png`;
      }
    }
  }, []);

  return (
    <Providers>
      <Splash>
        <Routes>
          {AuthRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
        <ModalContainer />
        <Toaster />
      </Splash>
    </Providers>
  );
};
