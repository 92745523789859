import { action, computed, makeObservable, observable } from 'mobx';
import { CurrenciesRequest, CurrenciesResponse, Currency } from '../models';
import { HttpResponse, currenciesController } from '../api';
import { DEFAULT_RESPONSE_LIST } from '../constants';
import { RootStore } from './index';

export class CurrenciesStore {
  rootStore: RootStore;

  public currencies = new HttpResponse<CurrenciesResponse>(DEFAULT_RESPONSE_LIST);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      currencies: observable,
      setCurrencies: action.bound,
      userCurrency: computed,
      currenciesMap: computed
    })
  }

  public get userCurrency(): Partial<Currency> {
    const userCurrencyCode = this.rootStore.userSettingsStore.userSettings.data.currencyCode;
    return this.currencies.data.items.find((item) => item.name === userCurrencyCode) || {};
  }

  public get currenciesMap(): Record<string, Currency> {
    return this.currencies.data.items.reduce<Record<string, Currency>>((acc, curr) => {
      acc[curr.name] = curr;
      return acc;
    }, {});
  }

  public setCurrencies(value: HttpResponse<CurrenciesResponse>): void {
    this.currencies = value;
  }

  public async getCurrencies(params?: CurrenciesRequest): Promise<void> {
    this.setCurrencies(this.currencies.fetching());
    const data = await currenciesController.getCurrencies(params);
    this.setCurrencies(this.currencies.fetched(data));
  }

}