import React from 'react';
import {observer} from 'mobx-react-lite';
import {Navigate} from 'react-router-dom';

import {RoutePaths} from '../../../constants';
import Login from '../../../view/pages/login';
import {useStore} from '../../../hooks';

interface IsNotAuthLayerProps {
	children: React.ReactNode;
}

export const IsNotAuthLayer: React.FC<IsNotAuthLayerProps> = observer(({children}) => {

	const {authStore: {isAuth}} = useStore();

	return (
			<>
				{
					isAuth ? <Navigate to={RoutePaths.Dashboard}/> : children
				}
			</>
	);
});
