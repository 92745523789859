import { useContext } from 'react';

import { TranslationContext } from '../contexts';

export const useTranslation = () => {

  const translations = useContext(TranslationContext);

  const translate = (key: string): string => {
    return translations[key] || key;
  };
  
  return translate;
};
