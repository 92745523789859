import {useCallback, useEffect, useState} from 'react';

import {TableColumn, TableRowAction} from '../models';
import {TableColumnsRow} from '../table-columns-row';
import {TableEmpty} from './table-empty';
import {TableBody} from './table-body';
import {Loader} from '../../loader';
import {TableColumnType} from '../enums';
import {Ticket} from '../../../../../models';

interface TableContentProps {
	rowKey?: string;
	noData: boolean;
	loading: boolean;
	columns: Array<TableColumn>;
	rows: Array<Record<string, any>>;
	totals?: Record<string, React.ReactNode>;
	onRowClick: (row: any, index: number) => void;
	getRowActions?: (row: any, index: number) => Array<TableRowAction>;
}

export const TableContent: React.FC<TableContentProps> = ({
																														rows,
																														totals,
																														rowKey,
																														noData,
																														loading,
																														columns,
																														onRowClick,
																														getRowActions,
																													}) => {

	const [rowsData, setRowsData] = useState(rows);

	useEffect(() => {
		setRowsData(rows)
	}, [rows])

	const sortData = useCallback((dataKey: string, reverse: boolean, type?: TableColumnType) => {
		let filteredData;

		if (type === TableColumnType.Date) {
			filteredData = rowsData.sort((a, b) => new Date(a[dataKey]).getTime() - new Date(b[dataKey]).getTime())
		} else {
			filteredData = rowsData.sort((a, b) => a[dataKey] - b[dataKey]);
		}

		setRowsData(reverse ? [...filteredData.reverse()] : [...filteredData])
	}, [rowsData, setRowsData])

	return (
		<div className="table-holder">
			<TableColumnsRow columns={columns} sortData={sortData} />
			{
				(noData || loading) ? (
					<div className="table-empty-state">
						{loading ? <Loader/> : <TableEmpty/>}
					</div>
				) : (
					<TableBody
						rows={rowsData}
						rowKey={rowKey}
						totals={totals}
						columns={columns}
						onRowClick={onRowClick}
						getRowActions={getRowActions}
					/>
				)
			}

		</div>
	);
};
