import {action, makeObservable, observable, toJS} from 'mobx';

import {AddUserData, changePassword, GetUserRequest, ResponseList, User, UsersResponse} from '../models';
import {HttpResponse, usersController} from '../api';
import {DEFAULT_RESPONSE_LIST} from '../constants';
import {RootStore} from './index';

export class UsersStore {

	private rootStore: RootStore;

	public users = new HttpResponse<UsersResponse>(DEFAULT_RESPONSE_LIST);

	public userById = new HttpResponse<Partial<User>>({});

	public managersForFilter = new HttpResponse<Array<User>>([]);

	public cashiersForFilter = new HttpResponse<Array<User>>([]);

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeObservable(this, {
			users: observable,
			userById: observable,
			managersForFilter: observable,
			cashiersForFilter: observable,
			setUsers: action.bound,
			setUserById: action.bound,
			setManagersForFilter: action.bound,
			setCashiersForFilter: action.bound,
		});
	}

	public setUsers(value: HttpResponse<UsersResponse>) {
		this.users = value;
	}

	public setUserById(value: HttpResponse<Partial<User>>) {
		this.userById = value;
	}

	public resetUserById() {
		this.setUserById(this.userById.fetched({}));
	}

	public setManagersForFilter(value: HttpResponse<Array<User>>) {
		this.managersForFilter = value;
	}

	public setCashiersForFilter(value: HttpResponse<Array<User>>) {
		this.cashiersForFilter = value;
	}

	public async getUsers(params?: GetUserRequest): Promise<ResponseList<User>> {
		this.setUsers(this.users.fetching());
		const data = await usersController.getUsers(params);
		this.setUsers(this.users.fetched(data));
		return data
	}

	public async getUserById(id: number): Promise<void> {
		this.setUserById(this.userById.fetching());
		const data = await usersController.getUserById(id);
		this.setUserById(this.userById.fetched(data));
	}

	public async getManagersForFilter(params?: GetUserRequest) {
		this.setManagersForFilter(this.managersForFilter.fetching());
		const data = await usersController.getUsers(params);
		this.setManagersForFilter(this.managersForFilter.fetched(data.items));
	}

	public async getCashiersForFilter(params?: GetUserRequest) {
		this.setCashiersForFilter(this.cashiersForFilter.fetching());
		const data = await usersController.getUsers(params);
		this.setCashiersForFilter(this.cashiersForFilter.fetched(data.items));
	}

	public async addUser(data: AddUserData): Promise<void> {
		return await usersController.addUser(data);
	}

	public async blockUser(id: number): Promise<void> {
		return await usersController.blockUser(id);
	}

	public async unblockUser(id: number): Promise<void> {
		return await usersController.unblockUser(id);
	}

	public async resetFilteredData() {
		this.setCashiersForFilter(this.cashiersForFilter.fetched([]));
		this.setManagersForFilter(this.managersForFilter.fetched([]));
	}

	public async resetPin(id: number): Promise<void> {
		return await usersController.resetPin(id);
	}

	public async sendResetPasswordEmail(email: string): Promise<void> {
		await usersController.sendResetPasswordEmail(email);
	}

	public async resetPassword(newPassword: string, token: string, userId: string ): Promise<void> {
		await usersController.resetPassword(newPassword, token, userId);
	}

	public async confirmEmail(token: string, userId: string ): Promise<void> {
		await usersController.confirmEmail(token, userId);
	}

	public async changePassword(params: changePassword): Promise<void> {
		await usersController.changePassword(params);
	}
}