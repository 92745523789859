export const RegexConstants = {
  Number: /^\d{0,9}(\.\d{0,2})?$/,
  NumberWithEmptyCase: /^(?:(?:0\.\d*[1-9]\d*|0\.\d+|[1-9]\d*|0|)$|)$/,
  CharactersLength: /^.{8,}$/,
  MinOneNumber: /^(?=.*\d).*$/,
  Minute: /^(?:[0-5]?[0-9]|59)$/,
  pinCode: /^\d{0,9}(\.\d{0,2})?$/,
  Hours: /^(?:[01]?[0-9]|2[0-3]|)$/,
  AllNumbers: /^.?$-?[0-9]*\.?[0-9]*$/,
  Amount: /^([0]{1}|([1-9]{1}\d*))(?:.\d{0,2})?$|^$/,
  UppercaseAndLowerCase: /^(?=.*[a-z])(?=.*[A-Z]).*$/,
  Email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  BusinessBetShopName: /^(?!.*\s{2,})(?!^\s)(?!.*\s$).{1,50}$/,
  NonNegativeNumbers: /^([0]{1}|([1-9]{1}\d*))(?:\.\d{0,10})?$|^$/,
  SpecialCharacter: /^(?=.*[!@#$%^&*()_+[\]{}|\\;:'",.<>?/`~])[^\s]*$/,
  Password:
    /^(?=.?[A-Z])(?=(.[a-z]){1,})(?=(.[\d]){1,})(?=(.[\W]){1,})(?!.*\s).{6,}$/,
};
