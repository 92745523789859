import { EnvironmentTypes } from "./enums";
import { Environment } from "./models";

// TODO: check environments data

export const STAGE_DOMAIN = "https://gravity-bo-stage.rpd.cloud";
// export const STAGE_DOMAIN = 'https://gravity-bo.pascalgaming.com';
export const STAGE_MEDIA_DOMAIN = "https://gravity-stage.rpd.cloud";

export const LIVE_MEDIA_DOMAIN = "https://pg.vbet.am";

const reactAppEnv = process.env.REACT_APP_ENV as EnvironmentTypes;

const dev: Environment = {
  production: false,
  applicationId: 102,
  httpUrl: `${STAGE_DOMAIN}`,
  mediaUrl: `${STAGE_MEDIA_DOMAIN}`,
  translationsHttpUrl: "https://rpd-translations-stage.betcoapps.com",
};

// `$STAGE_DOMAIN}/translations`

const stage: Environment = {
  production: false,
  applicationId: 102,
  httpUrl: `${STAGE_DOMAIN}`,
  mediaUrl: `${STAGE_MEDIA_DOMAIN}`,
  translationsHttpUrl: "https://rpd-translations-stage.betcoapps.com",
};
// `${window.location.origin}/translations`

const live: Environment = {
  production: true,
  applicationId: 61,
  httpUrl: `${window.location.origin}`,
  mediaUrl: `${LIVE_MEDIA_DOMAIN}`, // TODO NEED TO CHANGE LIVE
  translationsHttpUrl: `${window.location.origin}/translations`,
};

const envs = {
  [EnvironmentTypes.Development]: dev,
  [EnvironmentTypes.Staging]: stage,
  [EnvironmentTypes.Production]: live,
};

export const env = envs[reactAppEnv];
