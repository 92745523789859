export enum GameGroupsTypes {
  NUMBER_GAMES,
  VIRTUAL_SPORTS,
  // VIRTUAL_RACING,
  // CARD_GAMES,
  // PRE_RECORDED_GAMES,
  // CRASH_GAMES,
}

export const GameGroups: { [key: number]: string } = {
  [GameGroupsTypes.NUMBER_GAMES]: "Games",
  [GameGroupsTypes.VIRTUAL_SPORTS]: "Virtual sport",
  // [GameGroupsTypes.VIRTUAL_RACING]: 3,
  // [GameGroupsTypes.CARD_GAMES]: 3,
  // [GameGroupsTypes.PRE_RECORDED_GAMES]: 3,
  // [GameGroupsTypes.CRASH_GAMES]: 3,
};

export const GameGroupsList = [
  {
    name: GameGroups[GameGroupsTypes.NUMBER_GAMES],
    id: GameGroupsTypes.NUMBER_GAMES,
  },
  {
    name: GameGroups[GameGroupsTypes.VIRTUAL_SPORTS],
    id: GameGroupsTypes.VIRTUAL_SPORTS,
  },
];
