import React, { useCallback, useState } from 'react';

import { PopoverPopup } from '../popover-popup';
import { Icons } from '../../../../enums';
import { Image } from '../image';
import { Icon } from '../icon';

import './style.scss';

interface PopoverOptionProps {
  icon?: Icons;
  label: string;
  imageUrl?: string;
  className?: string;
  iconColorHex?: string;
  childrenTitle?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
}

export const PopoverOption: React.FC<PopoverOptionProps> = ({
  icon,
  label,
  onClick,
  imageUrl,
  children,
  iconColorHex,
  childrenTitle,
  className = '',
}) => {

  const [nestedContentOpen, setNestedContentOpen] = useState(false);

  const handleClick = useCallback((event: React.MouseEvent) => {
    children ? setNestedContentOpen(true) : onClick?.(event);
  }, [children, setNestedContentOpen, onClick]);

  const handleBackClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setNestedContentOpen(false);
  }, []);
  
  return (
    <div
      onClick={handleClick}
      className={`popover-item ${className}`}
    >
      <div className="popover-item-content">
        <div className="icon-image-wr">
          {
            icon ? (
              <Icon type={icon} colorHex={iconColorHex} />
            ) : null
          }
          {
            imageUrl ? (
              <Image src={imageUrl} className="popover-image" />
            ) : null
          }
        </div>
        <p className="popover-txt">{label}</p>
        {
          !!children && (
            <>
              <Icon type={Icons.ArrowRight} size="xs" />
              {
                nestedContentOpen && (
                  <PopoverPopup direction="absolute">
                    <div className="nested-popover-heading">
                      <div
                        onClick={handleBackClick}
                        className="nested-popover-heading-content"
                      >
                        <Icon type={Icons.ArrowLeft} size="xs" />
                        <p className="nested-popover-txt">{ childrenTitle }</p>
                      </div>
                    </div>
                    <div className="nested-popover-content">
                      { children }
                    </div>
                  </PopoverPopup>
                )
              }
            </>
          )
        }
      </div>
    </div>
  );
};
