import { Icons } from '../../../../enums';
import { Icon } from '../icon';

import './style.scss';

interface WidgetProps {
  icon: Icons;
  description: string;
  iconColorHex?: string;
  value: React.ReactNode;
}

export const Widget: React.FC<WidgetProps> = ({
  icon,
  value,
  description,
  iconColorHex
}) => {
  return (
    <div className="total-bet-amount-block-bottom-items">
      <Icon
        wrap
        type={icon}
        colorHex={iconColorHex}
        className='revenue-block-items-amount-block'
      />
        <div className="revenue-block-amount-wrp">
            <p className="revenue-block-amount-number">{ value }</p>
            <p className="revenue-block-amount-text">{ description }</p>
        </div>
    </div>
  );
};
