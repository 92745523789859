import { useMemo } from "react";
import { observer } from "mobx-react-lite";

import { dataToDropDownConverter } from "../../../../../helpers";
import { useStore, useTranslation } from "../../../../../hooks";
import { DropdownOptionValue } from "../../dropdown/models";
import { T } from "../../../../../constants";
import { Dropdown } from "../../dropdown";
import { toJS } from "mobx";

interface PartnerFilterProps {
  hasAll?: boolean;
  value?: DropdownOptionValue;
  onChange?: (value?: DropdownOptionValue | Array<DropdownOptionValue>) => void;
}

export const PartnerFilter: React.FC<PartnerFilterProps> = observer(
  ({ value, onChange, hasAll = true }) => {
    const t = useTranslation();
    const { partnerStore } = useStore();

    const {
      partnersForFilter: { data: partners },
    } = partnerStore;

    const partnersData = useMemo(() => {
      return dataToDropDownConverter(partners, {
        labelKey: "name",
        valueKey: "id",
      });
    }, [partners]);

    return (
      <Dropdown
        value={value}
        hasAll={hasAll}
        onChange={onChange}
        data={partnersData}
        label={t(T.PARTNER)}
        withSearch={!!partnersData}
      />
    );
  },
);
