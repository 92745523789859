import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import Input from "../input";

interface NumberInputProps {
  value: string | number;
  label?: string;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  numberRegEx?: RegExp;
  errorMessage?: string;
  className?: string;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  value,
  label,
  onChange,
  disabled,
  readonly,
  placeholder,
  numberRegEx,
  errorMessage,
  className,
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (numberRegEx?.test(event.target.value)) {
        onChange(event);
      }
    },
    [numberRegEx, onChange],
  );

  return (
    <Input
      label={label}
      value={value || ""}
      readonly={readonly}
      disabled={disabled}
      className={className}
      onChange={handleChange}
      placeholder={placeholder}
      errorMessage={errorMessage}
    />
  );
};
