import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { combineQuery } from '../helpers';
import { useQuery } from './use-query';

export const useTable = () => {

  const navigate = useNavigate();
  const { query } = useQuery();

  const onPaginationChange = useCallback((page: number, selectorValue: number) => {
    navigate(combineQuery({
      ...query,
      skip: (page - 1) * selectorValue,
      take: selectorValue
    }));
  }, [query, navigate]);

  return {
    onPaginationChange
  };
};
