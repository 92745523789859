import { action, makeObservable, observable, toJS } from "mobx";

import {
  AddPartnerData,
  Game,
  GamesResponse,
  GetGamesRequest,
  GetPartnersBusinessRequest,
  GetPartnersRequest,
  Partner,
  PartnerBusiness,
  PartnerBusinessResponse,
  PartnersResponse,
} from "../models";
import { HttpResponse, partnersController } from "../api";
import { DEFAULT_RESPONSE_LIST, LocalStorageKeys } from "../constants";
import { rootStore, RootStore } from "./index";
import { LocalStorageHelper } from "../helpers";
import { UserType } from "../enums";

export class PartnersStore {
  private rootStore: RootStore;

  public partners = new HttpResponse<PartnersResponse>(DEFAULT_RESPONSE_LIST);

  public partnerById = new HttpResponse<Partial<Partner>>({});

  public partnerBusiness = new HttpResponse<PartnerBusinessResponse>(null);

  public selectedBusiness: PartnerBusiness = {} as PartnerBusiness;

  public partnersForFilter = new HttpResponse<Array<Partner>>([]);

  public gamesForFilter = new HttpResponse<Array<Game>>([]);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      gamesForFilter: observable,
      partners: observable,
      partnerById: observable,
      partnerBusiness: observable,
      selectedBusiness: observable,
      partnersForFilter: observable,
      setPartners: action.bound,
      setPartnerById: action.bound,
      setPartnersForFilter: action.bound,
      setPartnerBusiness: action.bound,
      setSelectedBusiness: action.bound,
      resetSelectedBusiness: action.bound,
    });
  }

  public setPartners(value: HttpResponse<PartnersResponse>): void {
    this.partners = value;
  }

  public setGamesForFilter(value: HttpResponse<Array<Game>>): void {
    this.gamesForFilter = value;
  }

  public setPartnersForFilter(value: HttpResponse<Array<Partner>>): void {
    this.partnersForFilter = value;
  }

  public setPartnerBusiness(
    value: HttpResponse<PartnerBusinessResponse>,
  ): void {
    this.partnerBusiness = value;
  }

  public setPartnerById(value: HttpResponse<Partial<Partner>>): void {
    this.partnerById = value;
  }

  public setSelectedBusiness(value: PartnerBusiness) {
    const partnerId = rootStore.userSettingsStore.userSettings.data.partnerId;
    const currentBusinesses =
      LocalStorageHelper.getItem(LocalStorageKeys.SelectedBusiness) || {};
    if (partnerId) {
      LocalStorageHelper.setItem(LocalStorageKeys.SelectedBusiness, {
        ...currentBusinesses,
        [partnerId]: value.id,
      });
    }

    this.selectedBusiness = value;
  }

  public resetSelectedBusiness() {
    this.selectedBusiness = {} as PartnerBusiness;
  }

  public async getPartnersForFilter(
    params?: GetPartnersRequest,
  ): Promise<void> {
    this.setPartnersForFilter(this.partnersForFilter.fetching());
    const data = await partnersController.getPartners(params);
    this.setPartnersForFilter(this.partnersForFilter.fetched(data.items));
  }

  public async getGamesForFilter(params?: GetGamesRequest): Promise<void> {
    this.setGamesForFilter(this.gamesForFilter.fetching());
    const data = await partnersController.getGames(params);
    this.setGamesForFilter(this.gamesForFilter.fetched(data.items));
  }

  public async getPartnerBusiness(
    params?: GetPartnersBusinessRequest,
  ): Promise<void> {
    this.setPartnerBusiness(this.partnerBusiness.fetching());
    const data = await partnersController.getPartnerBusiness(params);
    const storageBusinessData = LocalStorageHelper.getItem(
      LocalStorageKeys.SelectedBusiness,
    );

    const { userType, partnerId } =
      rootStore.userSettingsStore.userSettings.data;

    if (partnerId && storageBusinessData && storageBusinessData[partnerId]) {
      this.setSelectedBusiness(
        data?.items.find(
          (item) => item.id === storageBusinessData[partnerId],
        ) || ({} as PartnerBusiness),
      );
    }

    if (userType === UserType.Partner && data?.count === 1) {
      this.setSelectedBusiness(data.items[0]);
    }

    this.setPartnerBusiness(this.partnerBusiness.fetched(data));
  }

  public async addPartner(data: AddPartnerData): Promise<Partner> {
    return await partnersController.addPartner(data);
  }

  public async blockPartner(id: number): Promise<void> {
    return await partnersController.blockPartner(id);
  }

  public async unblockPartner(id: number): Promise<void> {
    return await partnersController.unblockPartner(id);
  }
}
