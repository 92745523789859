import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import {
  AddEditViewWrapper,
  AddEditViewHeader,
  AddEditViewContent,
  AddEditViewFooter,
  Breadcrumb,
  DialogModal,
  Loader,
} from "../../../components/shared";
import { Icons, UserType } from "../../../../enums";
import { RoutePaths, T } from "../../../../constants";
import { useStore, useTranslation, useForm } from "../../../../hooks";
import { BreadcrumbItemData } from "../../../components/shared/breadcrumb/models";
import EditGameControlForm from "./edit-game-control-form";
import { betShops } from "../../../../models/api/games-control";
import { Toaster } from "../../../containers";

interface EditGamesControlProps {}

const EditGamesControl: React.FC<EditGamesControlProps> = observer(() => {
  const t = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    partnerStore,
    gamesControlStore,
    userSettingsStore: {
      userSettings: {
        data: { userType },
      },
    },
  } = useStore();

  const {
    selectedBusiness,
    partnerBusiness: { data },
  } = partnerStore;

  const [isDialogModalOpen, setIsDialogModalOpen] = useState(false);

  const {
    betShopList: {
      data: { items },
    },
    gameById: { data: gameById },
  } = gamesControlStore;

  const { type, name, partnerId } = gameById;

  const [betShopsItems, setBetShopsItems] = useState<betShops[]>(items);

  const { values, setValues, handleDropdownChange } = useForm({
    gameType: null,
    partnerId: null,
    businessUnitId: null,
  });

  const editable = !!id;

  useEffect(() => {
    setValues({ ...values, gameType: type });
  }, [type]);

  useEffect(() => {
    setBetShopsItems(items);
  }, [items]);

  useEffect(() => {
    if (!data?.count) {
      setBetShopsItems([]);
      setValues({ ...values, businessUnitId: null });
    }
  }, [data?.count]);

  useEffect(() => {
    getGameById();
  }, []);

  useEffect(() => {
    if (partnerId) {
      getBusinesses({ partnerId });
    }
  }, [partnerId]);

  useEffect(() => {
    const businessId = values.businessUnitId || selectedBusiness.id;
    if (userType) {
      if (
        (userType === UserType.SuperAdmin || userType === UserType.Admin) &&
        businessId === undefined
      )
        return;
      getBetShops(businessId);
    }
  }, [values.businessUnitId, selectedBusiness.id]);

  const navigateToGameControlList = useCallback(
    () => navigate(RoutePaths.GameControl),
    [],
  );

  const getBetShops = useCallback(
    (businessId: number) => {
      if (id)
        gamesControlStore.getBetShops({
          gameId: +id,
          businessUnitId: businessId,
        });
    },
    [id, values],
  );

  const getBusinesses = useCallback((param?: any) => {
    partnerStore.getPartnerBusiness(param);
  }, []);

  const getGameById = useCallback(() => {
    if (id) gamesControlStore.getGameById(+id);
  }, []);

  const resetBetShopList = useCallback(() => {
    gamesControlStore.resetBetShopList();
  }, []);

  const breadcrumbData: Array<BreadcrumbItemData> = useMemo(() => {
    return [
      {
        name: t(T.GAMES_CONTROL),
        onClick: navigateToGameControlList,
      },
      {
        active: true,
        name: name as string,
      },
    ];
  }, [navigateToGameControlList, editable, t]);

  const saveBetShopsChanges = () => {
    let response = null;
    if (id) {
      if (betShopsItems.length) {
        response = gamesControlStore.updateBetShops({
          gameId: +id,
          betshopGames: betShopsItems,
        });
      }
      if (values.gameType !== null) {
        response = gamesControlStore.updateGroupType(+id, values.gameType);
      }
    }

    if (response) {
      response
        .then(() => {
          Toaster.success({ message: t(T.GAME_CONFIGURED_SUCCESSFULLY) });
          navigateToGameControlList();
        })
        .catch((error: { errorMessage: string }) => {
          Toaster.error({ message: t(error?.errorMessage) });
        })
        .finally(() => {
          setIsDialogModalOpen(false);
        });
    }
  };

  const disableSaveBtn = useMemo(() => {
    return (
      (!!betShopsItems.length && betShopsItems !== items) ||
      values.gameType !== null
    );
  }, [betShopsItems, values]);

  return (
    <AddEditViewWrapper>
      <AddEditViewHeader>
        <Breadcrumb items={breadcrumbData} />
      </AddEditViewHeader>
      {!editable ? (
        <Loader className="add-edit-view-loader" />
      ) : (
        <>
          <AddEditViewContent>
            <EditGameControlForm
              values={values}
              partnerId={partnerId}
              betShopsItems={betShopsItems}
              setBetShopsItems={setBetShopsItems}
              resetBetShopList={resetBetShopList}
              hideFilter={
                !(
                  userType === UserType.SuperAdmin ||
                  userType === UserType.Admin
                )
              }
              handleDropdownChange={handleDropdownChange}
            />
          </AddEditViewContent>
          {
            <AddEditViewFooter
              addMode={editable}
              editMode
              createDisabled={!disableSaveBtn}
              setEditMode={() => {}}
              onCancel={navigateToGameControlList}
              onSave={() => setIsDialogModalOpen(true)}
            />
          }
          {isDialogModalOpen && (
            <DialogModal
              iconColorHex="#007fdb"
              onConfirm={saveBetShopsChanges}
              confirmText={t(T.SAVE)}
              icon={Icons.InfoCircle}
              cancelText={t(T.DISCARD)}
              onClose={() => setIsDialogModalOpen(false)}
              description={t(T.GAME_CONFIG_SAVE_CONFIRMATION)}
            />
          )}
        </>
      )}
    </AddEditViewWrapper>
  );
});

export default EditGamesControl;
