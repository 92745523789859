import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { T } from "../../../../../constants";
import { Icons } from "../../../../../enums";
import TransferListSection from "../../transfer-list-section";
import { dataToDropDownConverter } from "../../../../../helpers";
import { GameGroupsList } from "../../../../../enums/game-groups";
import { betShops } from "../../../../../models/api/games-control";
import { Button, Dropdown, InputsSection } from "../../../../components/shared";
import { DropdownOptionValue } from "../../../../components/shared/dropdown/models";
import { BusinessFilter } from "../../../../components/shared/main-filters/business";
import { useTranslation } from "../../../../../hooks";

interface EditGameControlFormProps {
  values: any;
  partnerId?: number;
  hideFilter: boolean;
  betShopsItems: Array<betShops>;
  resetBetShopList: () => void;
  setBetShopsItems: (data: any) => void;
  handleDropdownChange: (value: DropdownOptionValue, key: string) => void;
}

const EditGameControlForm: React.FC<EditGameControlFormProps> = ({
  values,
  partnerId,
  hideFilter,
  betShopsItems,
  setBetShopsItems,
  resetBetShopList,
  handleDropdownChange,
}) => {
  const t = useTranslation();

  const [activeBetShopSelectedData, setActiveBetShopSelectedData] = useState<{
    [key: number]: boolean;
  }>({});

  const [inactiveBetShopSelectedData, setInactiveBetShopSelectedData] =
    useState<{
      [key: number]: boolean;
    }>({});

  const [activeBetShops, setActiveBetShops] = useState<betShops[]>([]);
  const [inActiveBetShops, setInActiveBetShops] = useState<betShops[]>([]);

  useEffect(() => {
    return () => {
      resetBetShopList();
    };
  }, []);

  useEffect(() => {
    const data = betShopsItems.reduce<{
      activeBetShops: betShops[];
      inActiveBetShops: betShops[];
    }>(
      (acc, currentData) => {
        acc[currentData.isActive ? "activeBetShops" : "inActiveBetShops"].push(
          currentData,
        );

        return acc;
      },
      { inActiveBetShops: [], activeBetShops: [] },
    );

    setActiveBetShops(data.activeBetShops);
    setInActiveBetShops(data.inActiveBetShops);
  }, [betShopsItems]);

  const transferActiveBetShops = useCallback(() => {
    if (!Object.keys(activeBetShopSelectedData).length) return;

    const currentData = [...betShopsItems];
    setBetShopsItems(
      currentData.map((item) => ({
        ...item,
        isActive: activeBetShopSelectedData[item.betshopId]
          ? false
          : item.isActive,
      })),
    );

    setActiveBetShopSelectedData({});
  }, [activeBetShops, inActiveBetShops, activeBetShopSelectedData]);

  const transferInActiveBetShops = useCallback(() => {
    if (!Object.keys(inactiveBetShopSelectedData).length) return;

    const currentData = [...betShopsItems];
    setBetShopsItems(
      currentData.map((item) => ({
        ...item,
        isActive: inactiveBetShopSelectedData[item.betshopId] || item.isActive,
      })),
    );
    setInactiveBetShopSelectedData({});
  }, [activeBetShops, inActiveBetShops, inactiveBetShopSelectedData]);

  const gameGroupData = useMemo(() => {
    return dataToDropDownConverter(GameGroupsList, {
      labelKey: "name",
      valueKey: "id",
    });
  }, []);

  return (
    <div className="page-info-holder">
      <div className="game-control">
        <div className="game-control-inner">
          {!hideFilter ? (
            <InputsSection title={T.GAMES_CONTROL}>
              <Dropdown
                withSearch
                value={values.gameType}
                onChange={(value) =>
                  handleDropdownChange(value as DropdownOptionValue, "gameType")
                }
                data={gameGroupData}
                label={t(T.CHOOSE_GAME_GROUP)}
              />
            </InputsSection>
          ) : null}
          {!hideFilter && partnerId ? (
            <InputsSection title={T.BETSHOP}>
              <BusinessFilter
                hasAll={false}
                partnerId={partnerId}
                value={values.businessUnitId}
                onChange={(value) =>
                  handleDropdownChange(
                    value as DropdownOptionValue,
                    "businessUnitId",
                  )
                }
              />
            </InputsSection>
          ) : null}
        </div>

        <div className="transfer-wrapper">
          <TransferListSection
            list={activeBetShops}
            title={T.ACTIVE_BET_SHOPS}
            selectedItems={activeBetShopSelectedData}
            onChange={setActiveBetShopSelectedData}
          />
          {/*TODO create component transferredAction*/}
          <div className="transfer-operation">
            <Button
              icon={Icons.ArrowLeft}
              className="transfer-btn"
              appearance="none"
              onClick={transferInActiveBetShops}
            />
            <Button
              icon={Icons.ArrowRight}
              className="transfer-btn"
              appearance="none"
              onClick={transferActiveBetShops}
            />
          </div>

          <div className="transfer-list">
            <TransferListSection
              list={inActiveBetShops}
              title={T.INACTIVE_BET_SHOPS}
              selectedItems={inactiveBetShopSelectedData}
              onChange={setInactiveBetShopSelectedData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(EditGameControlForm);
