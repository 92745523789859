import React from 'react';

import './style.scss';

interface AddEditViewWrapperProps {
  children: React.ReactNode;
}

export const AddEditViewWrapper: React.FC<AddEditViewWrapperProps> = ({ children }) => {
  return (
    <div className="page-info">
      { children }
    </div>
  );
};
