import React, { useCallback } from "react";

import { useTranslation } from "../../../../../hooks";
import { Icons } from "../../../../../enums";
import { T } from "../../../../../constants";
import { Button } from "../../button";

interface AddEditViewFooterProps {
  addMode: boolean;
  editMode: boolean;
  onSave: () => void;
  onCancel?: () => void;
  createDisabled?: boolean;
  setEditMode: (value: boolean) => void;
}

export const AddEditViewFooter: React.FC<AddEditViewFooterProps> = ({
  onSave,
  onCancel,
  addMode,
  editMode,
  setEditMode,
  createDisabled,
}) => {
  const t = useTranslation();

  const handleCancel = useCallback(() => {
    if (editMode) {
      setEditMode(false);
    }
    if (onCancel) {
      onCancel();
    }
  }, [editMode, setEditMode, onCancel]);

  return (
    <div className="page-info-footer">
      <div className="page-info-footer-actions">
        {!addMode && !editMode ? (
          <Button
            key="edit"
            text={t(T.EDIT)}
            preIcon={Icons.Edit}
            appearance="light-secondary"
            onClick={() => setEditMode(true)}
            className="page-info-footer-action"
          />
        ) : (
          <>
            {onCancel ? (
              <Button
                key="cancel"
                text={t(T.CANCEL)}
                onClick={handleCancel}
                appearance="secondary"
                className="page-info-footer-action"
              />
            ) : null}
            <Button
              key="save"
              onClick={onSave}
              disabled={createDisabled}
              className="page-info-footer-action"
              text={t(editMode ? T.SAVE : T.CREATE)}
            />
          </>
        )}
      </div>
    </div>
  );
};
