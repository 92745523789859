import {
	AddPartnerData, GamesResponse, GetPartnersBusinessRequest,
	GetPartnersRequest,
	Partner,
	PartnerBusiness,
	PartnerBusinessResponse,
	PartnersResponse
} from '../../../models';
import {BaseApiService} from '../../base-api-service';
import {ApiVersions} from '../../../enums';
import {env} from '../../../environment';

class PartnersController extends BaseApiService {

	public async getPartners(params?: GetPartnersRequest): Promise<PartnersResponse> {
		const url = `${this.url}/api/${ApiVersions.V1}/partner`;
		return await this.http.get(url, {params});
	}

	public async getPartnerBusiness(params?: GetPartnersBusinessRequest): Promise<PartnerBusinessResponse> {
		const url = `${this.url}/api/${ApiVersions.V1}/BusinessUnit`;
		return await this.http.get(url, {params});
	}

	public async addPartner(data: AddPartnerData): Promise<Partner> {
		const url = `${this.url}/api/${ApiVersions.V1}/partner`;
		return await this.http.post(url, data);
	}

	public async blockPartner(id: number): Promise<void> {
		const url = `${this.url}/api/${ApiVersions.V1}/partner/block/${id}`;
		return await this.http.put(url);
	}

	public async unblockPartner(id: number): Promise<void> {
		const url = `${this.url}/api/${ApiVersions.V1}/partner/unblock/${id}`;
		return await this.http.put(url);
	}

	public async getGames(params?: GetPartnersRequest): Promise<GamesResponse> {
		const url = `${this.url}/api/${ApiVersions.V1}/game`;
		return await this.http.get(url, {params});
	}


}

export const partnersController = new PartnersController({url: env.httpUrl});
