import { IsAuthLayer, IsNotAuthLayer } from './layers';
import { defineGuards } from './define-guards';
import { RoutePaths } from '../constants';
import Login from '../view/pages/login';
import { RouteModel } from '../models';
import { App } from '../view/App';
import {ForgotPassword} from '../view/pages/login/forgot-password';
import {LoginForm} from '../view/pages/login/login-form';
import {NewPassword} from '../view/pages/login/new-password';

export const AuthRoutes: Array<RouteModel> = [
  {
    path: RoutePaths.Login,
    component: () => defineGuards([IsNotAuthLayer], LoginForm),
  },
  {
    path: RoutePaths.ForgotPassword,
    component: () => defineGuards([IsNotAuthLayer], ForgotPassword),
  },
  {
    path: RoutePaths.NewPassword,
    component: () => defineGuards([IsNotAuthLayer], NewPassword),
  },
  {
    path: RoutePaths.ResetPassword,
    component: () => defineGuards([IsNotAuthLayer], NewPassword),
  },
  {
    path: RoutePaths.WildCard,
    component: () => defineGuards([IsAuthLayer], App),
  }
];
