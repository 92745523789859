import { defineGuards } from "./define-guards";
import { lazyWithRetry } from "../helpers";
import { RoutePaths } from "../constants";
import { RouteModel } from "../models";
import { IsAuthLayer } from "./layers";
import GamesControl from "../view/pages/games-control";
import EditGamesControl from "../view/pages/games-control/add-edit-game-control";
import { NewPassword } from "../view/pages/login/new-password";
import { UserType } from "../enums";

const MainRedirect = lazyWithRetry(() => import("./main-redirect"));
const NotFound = lazyWithRetry(() => import("../view/pages/not-found"));
const Dashboard = lazyWithRetry(() => import("../view/pages/dashboard"));
const BetHistory = lazyWithRetry(() => import("../view/pages/bet-history"));

const Partners = lazyWithRetry(() => import("../view/pages/partners"));
const PartnersList = lazyWithRetry(
  () => import("../view/pages/partners/partners-list"),
);

const GamesControlList = lazyWithRetry(
  () => import("../view/pages/games-control/games-control-list"),
);
const AddEditViewPartner = lazyWithRetry(
  () => import("../view/pages/partners/add-edit-view-partner"),
);

const Betshops = lazyWithRetry(() => import("../view/pages/betshops"));
const BetshopList = lazyWithRetry(
  () => import("../view/pages/betshops/betshop-list"),
);
const AddEditViewBetshop = lazyWithRetry(
  () => import("../view/pages/betshops/add-edit-betshop"),
);

const Configurations = lazyWithRetry(
  () => import("../view/pages/configurations"),
);
const Transactions = lazyWithRetry(
  () => import("../view/pages/transaction-history"),
);

const Managers = lazyWithRetry(() => import("../view/pages/managers"));
const ManagerList = lazyWithRetry(
  () => import("../view/pages/managers/manager-list"),
);
const AddEditViewManager = lazyWithRetry(
  () => import("../view/pages/managers/add-edit-manager"),
);

const Cashiers = lazyWithRetry(() => import("../view/pages/cashiers"));
const CashiersList = lazyWithRetry(
  () => import("../view/pages/cashiers/cashiers-list"),
);
const CreateCashier = lazyWithRetry(
  () => import("../view/pages/cashiers/add-edit-cashier"),
);

const BusinessList = lazyWithRetry(
  () => import("../view/pages/business-settings/business-list"),
);
const AddEditViewBusiness = lazyWithRetry(
  () => import("../view/pages/business-settings/add-edit-business"),
);
const BusinessSettings = lazyWithRetry(
  () => import("../view/pages/business-settings"),
);

export const MainRoutes: Array<RouteModel> = [
  {
    path: RoutePaths.Empty,
    component: MainRedirect,
  },
  {
    path: RoutePaths.Dashboard,
    component: () => defineGuards([IsAuthLayer], Dashboard),
  },
  {
    path: RoutePaths.BusinessSettings,
    component: () => defineGuards([IsAuthLayer], BusinessSettings),
    nestedRoutes: [
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], AddEditViewBusiness),
      },
    ],
  },
  {
    path: RoutePaths.NewPasswordAuth,
    component: () => defineGuards([IsAuthLayer], NewPassword),
  },
  {
    path: RoutePaths.Businesses,
    component: () => defineGuards([IsAuthLayer], BusinessSettings),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], BusinessList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], AddEditViewBusiness),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], AddEditViewBusiness),
      },
    ],
  },
  {
    path: RoutePaths.GameControl,
    component: () => defineGuards([IsAuthLayer], GamesControl),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], GamesControlList),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], EditGamesControl),
      },
    ],
  },
  {
    path: RoutePaths.BetHistory,
    component: () => defineGuards([IsAuthLayer], BetHistory),
  },
  {
    path: RoutePaths.NotFound,
    component: () => defineGuards([IsAuthLayer], NotFound),
  },
  {
    path: RoutePaths.Partners,
    component: () => defineGuards([IsAuthLayer], Partners),
    accessedUserTypes: [UserType.Admin, UserType.SuperAdmin],
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], PartnersList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], AddEditViewPartner),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], AddEditViewPartner),
      },
    ],
  },
  {
    path: RoutePaths.Betshops,
    component: () => defineGuards([IsAuthLayer], Betshops),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], BetshopList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], AddEditViewBetshop),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], AddEditViewBetshop),
      },
    ],
  },
  {
    path: RoutePaths.Managers,
    component: () => defineGuards([IsAuthLayer], Managers),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], ManagerList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], AddEditViewManager),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], AddEditViewManager),
      },
    ],
  },
  {
    path: RoutePaths.Cashiers,
    component: () => defineGuards([IsAuthLayer], Cashiers),
    nestedRoutes: [
      {
        path: "",
        component: () => defineGuards([IsAuthLayer], CashiersList),
      },
      {
        path: "add",
        component: () => defineGuards([IsAuthLayer], CreateCashier),
      },
      {
        path: ":id/edit",
        component: () => defineGuards([IsAuthLayer], CreateCashier),
      },
    ],
  },
  {
    path: RoutePaths.Configurations,
    component: Configurations,
  },
  {
    path: RoutePaths.Transactions,
    component: Transactions,
  },
  {
    path: RoutePaths.WildCard,
    component: NotFound,
  },
];
