export enum BetStatus {
  Lose,
  Win,
  Pending,
  Returned,
  Canceled,
  Unknown,
  Calculation,
  OnCancel,
}
