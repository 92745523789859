import { action, makeObservable, observable } from 'mobx';

import { GetTicketsRequest, Ticket, TicketsResponse } from '../models';
import { HttpResponse, ticketsController } from '../api';
import { DEFAULT_RESPONSE_LIST } from '../constants';
import { RootStore } from './index';

export class TicketsStore {

  rootStore: RootStore;

  public tickets = new HttpResponse<TicketsResponse>(DEFAULT_RESPONSE_LIST);

  public ticketById = new HttpResponse<Partial<Ticket>>({});
  public nestedTicketInfo = new HttpResponse<Partial<Ticket>>({});

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      tickets: observable,
      ticketById: observable,
      setTickets: action.bound,
      setTicketById: action.bound,
    });
  }

  public setTickets(value: HttpResponse<TicketsResponse>): void {
    this.tickets = value;
  }

  public setTicketById(value: HttpResponse<Partial<Ticket>>): void {
    this.ticketById = value;
  }

  public setNestedTicketById(value: HttpResponse<Partial<Ticket>>): void {
    this.nestedTicketInfo = value;
  }

  public async getTickets(params: GetTicketsRequest): Promise<void> {
    this.setTickets(this.tickets.fetching());
    const data = await ticketsController.getTickets(params);

    this.setTickets(this.tickets.fetched(data));
  }

  public async getTicketById(id: number): Promise<any> {
    this.setTicketById(this.ticketById.fetching());
    const data = await ticketsController.getTicketById(id);
    this.setTicketById(this.ticketById.fetched(data));

    return data
  }

  public resetTicketById() {
    this.setTicketById(this.ticketById.fetched({}));
  }

}