import { useCallback, useState, useRef } from "react";

import { DropdownOptionValue } from "../view/components/shared/dropdown/models";
import { DATE_FORMAT_BACK_WITHOUT_HORSE } from "../constants";
import moment from "moment";

export const useForm = (initialState: Record<string, any> = {}) => {
  const initialForm = useRef(initialState);

  const [values, setValues] = useState(initialState);

  const handleChange = useCallback((value: any, key: string): void => {
    setValues((values: any) => ({ ...values, [key]: value }));
  }, []);

  const handleInputChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      key: string,
    ): void => {
      const { value } = event.target;

      setValues((values: any) => ({ ...values, [key]: value }));
    },
    [],
  );

  const handleCheckBoxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string): void => {
      const { checked } = event.target;
      setValues((values: any) => ({ ...values, [key]: checked }));
    },
    [],
  );

  const handlePositiveNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string): void => {
      const { value } = event.target;
      const valueString = `${value}`;
      if (valueString[0] === "0" || valueString.includes("-")) return;
      setValues((values: any) => ({ ...values, [key]: value?.trim() }));
    },
    [],
  );

  const handleDropdownChange = useCallback(
    (
      value: Array<DropdownOptionValue> | DropdownOptionValue | undefined,
      key: string,
    ): void => {
      setValues((values: any) => ({ ...values, [key]: value }));
    },
    [],
  );

  const handleDatePickerChange = useCallback(
    (value: Date, key: string): void => {
      setValues((values: any) => ({
        ...values,
        [key]: moment(value).format(DATE_FORMAT_BACK_WITHOUT_HORSE),
      }));
    },
    [],
  );

  const resetForm = useCallback((): void => {
    setValues(initialForm.current);
  }, [initialForm]);

  return {
    values,
    setValues,
    resetForm,
    handleChange,
    handleInputChange,
    handleCheckBoxChange,
    handleDropdownChange,
    handleDatePickerChange,
    handlePositiveNumberChange,
  };
};
