export const parseQuery = (queryString: string): Record<string, any> => {
  const query: Record<string, any> = {};
  if (!queryString) return {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    if (query[decodeURIComponent(pair[0])]) {
      if (Array.isArray(query[decodeURIComponent(pair[0])])) {
        query[decodeURIComponent(pair[0])].push(decodeURIComponent(pair[1] || ''));
      } else {
        query[decodeURIComponent(pair[0])] = [
            query[decodeURIComponent(pair[0])],
            decodeURIComponent(pair[1] || '')
        ];
      }
    } else {
      query[decodeURIComponent(pair[0])] = (decodeURIComponent(pair[1])) ? (
        decodeURIComponent(pair[1])
      ) : decodeURIComponent(pair[1] || '');
    }
  }
  return query;
};
