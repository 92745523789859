import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { hasPermission } from "../../../../helpers";
import { useStore } from "../../../../hooks";
import { UserType } from "../../../../enums";
import { PartnerFilter } from "./partner";
import { ManagerFilter } from "./manager";
import { BetshopFilter } from "./betshop";
import { CashierFilter } from "./cashier";
import { BusinessFilter } from "./business";
import { toJS } from "mobx";

interface MainFiltersProps {
  hidePartners?: boolean;
  hideManagers?: boolean;
  hideBetshops?: boolean;
  isReset?: boolean;
  hideCashiers?: boolean;
  filters: Record<string, any>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

export const MainFilters: React.FC<MainFiltersProps> = observer(
  ({
    filters,
    isReset,
    setFilters,
    hidePartners = false,
    hideManagers = false,
    hideBetshops = false,
    hideCashiers = false,
  }) => {
    const { betshopsStore, partnerStore, usersStore, userSettingsStore } =
      useStore();

    const {
      userSettings: {
        data: { userType },
      },
    } = userSettingsStore;

    const { selectedBusiness } = partnerStore;

    const { partnerId, managerId, betshopId, cashierId, businessUnitId } =
      filters;

    const showBetshops = !hideBetshops;
    const showCashiers = !hideCashiers;

    const showPartners =
      !hidePartners &&
      hasPermission(userType as UserType, [
        UserType.SuperAdmin,
        UserType.Admin,
      ]);

    const showManagers =
      !hideManagers &&
      hasPermission(userType as UserType, [
        UserType.SuperAdmin,
        UserType.Admin,
        UserType.Partner,
      ]);

    useEffect(() => {
      if (!userType) return;

      if (userType === UserType.Partner) {
        if (selectedBusiness.id) {
          getAllUsers();
        }
      } else {
        getAllUsers();
      }

      return () => {
        betshopsStore.resetBetShopFilteredData();
        usersStore.resetFilteredData();
      };
    }, [userType, selectedBusiness]);

    useEffect(() => {
      if (isReset) {
        getAllUsers();
      }
    }, [isReset]);

    const getAllUsers = () => {
      getPartners();
      getBusinesses();
      getManagers(partnerId);
      getBetshops(partnerId, managerId);
      getCashiers(partnerId, selectedBusiness.id, managerId, betshopId);
    };

    // START --------- filters api calls
    const getPartners = useCallback(() => {
      if (!showPartners) return;
      partnerStore.getPartnersForFilter();
    }, [showPartners]);

    const getBusinesses = useCallback(() => {
      if (!showPartners) return;
      partnerStore.getPartnerBusiness();
    }, [showPartners]);

    const getManagers = useCallback(
      (partnerId?: number, businessUnitId?: number) => {
        if (!showManagers) return;
        usersStore.getManagersForFilter({
          partnerId,
          userType: UserType.Manager,
          businessUnitId: selectedBusiness.id || businessUnitId,
        });
      },
      [showManagers, businessUnitId, selectedBusiness],
    );

    const getBetshops = useCallback(
      (partnerId?: number, businessUnitId?: number, managerId?: number) => {
        if (!showBetshops) return;
        betshopsStore.getBetshopsForFilter({
          partnerId,
          managerId,
          businessUnitId: selectedBusiness.id || businessUnitId,
        });
      },
      [showBetshops, businessUnitId, selectedBusiness],
    );

    const getCashiers = useCallback(
      (
        partnerId?: number,
        businessUnitId?: number,
        managerId?: number,
        betshopId?: number,
      ) => {
        if (!showCashiers) return;

        usersStore.getCashiersForFilter({
          partnerId,
          managerId,
          betshopId,
          userType: UserType.Cashier,
          businessUnitId: selectedBusiness.id || businessUnitId,
        });
      },
      [showCashiers, businessUnitId, selectedBusiness],
    );

    const handlePartnerChange = useCallback(
      (partnerId?: number) => {
        getManagers(partnerId);
        getBetshops(partnerId);
        getCashiers(partnerId);
        setFilters((prev) => ({ ...prev, partnerId, businessUnitId: null }));
        partnerStore.getPartnerBusiness({ partnerId });
      },
      [getManagers, getBetshops, getCashiers, setFilters],
    );

    const handleBusinessChange = useCallback(
      (businessUnitId?: number) => {
        setFilters((prev) => ({ ...prev, businessUnitId }));
        getManagers(partnerId, businessUnitId);
        getCashiers(partnerId, businessUnitId);
        getBetshops(partnerId, businessUnitId);
      },
      [setFilters, partnerId, betshopId, managerId],
    );

    const handleManagerChange = useCallback(
      (managerId?: number) => {
        setFilters((prev) => ({ ...prev, managerId }));
        getBetshops(partnerId, businessUnitId, managerId);
        getCashiers(partnerId, businessUnitId, managerId);
      },
      [
        partnerId,
        betshopId,
        getBetshops,
        getCashiers,
        businessUnitId,
        setFilters,
      ],
    );

    const handleBetshopChange = useCallback(
      (betshopId?: number) => {
        setFilters((prev) => ({ ...prev, betshopId }));
        getCashiers(partnerId, businessUnitId, managerId, betshopId);
      },
      [partnerId, managerId, getCashiers, businessUnitId, setFilters],
    );

    const handleCashierChange = useCallback(
      (cashierId?: number) => {
        setFilters((prev) => ({ ...prev, cashierId }));
      },
      [setFilters],
    );

    return (
      <>
        {showPartners ? (
          <>
            <PartnerFilter
              value={partnerId}
              onChange={(value) => handlePartnerChange(value as number)}
            />
            <BusinessFilter
              hasAll
              value={businessUnitId}
              partnerId={filters.partnerId}
              onChange={(value) => handleBusinessChange(value as number)}
            />
          </>
        ) : null}

        {showManagers && (
          <ManagerFilter
            value={managerId}
            setFilters={setFilters}
            onChange={(value) => handleManagerChange(value as number)}
          />
        )}
        {showBetshops && (
          <BetshopFilter
            value={betshopId}
            setFilters={setFilters}
            onChange={(value) => handleBetshopChange(value as number)}
          />
        )}
        {showCashiers && (
          <CashierFilter
            value={cashierId}
            setFilters={setFilters}
            onChange={(value) => handleCashierChange(value as number)}
          />
        )}
      </>
    );
  },
);
