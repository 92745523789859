import { MutableRefObject } from "react";

import { ModalHeader } from "../modal-header";

interface ModalStructureProps {
  title?: string;
  size?: string;
  onClose?: () => void;
  children?: React.ReactNode;
  modalRef: MutableRefObject<HTMLDivElement | null>;
}

export const ModalStructure: React.FC<ModalStructureProps> = ({
  title,
  onClose,
  children,
  modalRef,
  size,
}) => {
  return (
    <div className="modal-holder">
      <div ref={modalRef} className={`popup-holder ${size || "sm"}`}>
        <ModalHeader title={title} onClose={onClose} />
        {children}
      </div>
    </div>
  );
};
