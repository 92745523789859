import {useState} from 'react';
import {TableColumnType} from '../../enums';

interface TableColumnTitleProps {
	name: string;
	dataKey: string;
	type?: TableColumnType;
	onClick: (dataKey: string, reverse: boolean, type?: TableColumnType) => void
}

export const TableColumnTitle: React.FC<TableColumnTitleProps> = ({
																																		name,
																																		onClick,
																																		dataKey,
																																		type
																																	}) => {
	const [reverseFilter, setReverseFilter] = useState(false)
	const filterData = () => {
		setReverseFilter(!reverseFilter)
		onClick(dataKey, !reverseFilter, type)
	}

	return (
		<div className="table-col">
			<p className="table-txt">{name}</p>
			{/* TODO: ordering */}
			<div className="table-header-col-action">
				<div className={`table-header-col-action-arrow ${!reverseFilter ? 'active' : ''}`} onClick={filterData}>
					<i className="icon-sort-single-arrow-up"/>
				</div>
				<div className={`table-header-col-action-arrow ${reverseFilter ? 'active' : ''}`} onClick={filterData}>
					<i className="icon-sort-single-arrow-down"/>
				</div>
			</div>
		</div>
	)
};
