import { AddProviderData, GetProvidersRequest, Provider, ProvidersResponse } from '../../../models';
import { BaseApiService } from '../../base-api-service';
import { ApiVersions } from '../../../enums';
import { env } from '../../../environment';

class ProvidersController extends BaseApiService {

  public async getProviders(params: GetProvidersRequest): Promise<ProvidersResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/provider`;
    return await this.http.get(url, { params });
  }

  public async getProviderById(id: number): Promise<Provider> {
    const url = `${this.url}/api/${ApiVersions.V1}/provider/${id}`;
    return await this.http.get(url);
  }

  public async addProvider(data: AddProviderData): Promise<Provider> {
    const url = `${this.url}/api/${ApiVersions.V1}/provider`;
    return await this.http.post(url, { data });
  }

}

export const providersController = new ProvidersController({ url: env.httpUrl });
