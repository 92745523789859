import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../../../hooks';
import { Currency } from '../currency';

interface AmountProps {
  amount: number; // amount by user currency
  currency?: string;
  isAdmin?: boolean;
  coinAmount: number; // amount by usd
}

export const Amount: React.FC<AmountProps> = observer(({
  amount,
  currency,
  coinAmount,
  isAdmin
}) => {
  
  const { currenciesStore: { userCurrency } } = useStore();

  const convert = isAdmin && userCurrency.name !== currency;

  const amountValue = convert ? coinAmount : amount;

  return (
    <Currency
      convert={convert}
      value={amountValue}
      currencyCode={isAdmin ? userCurrency.name : currency}
    />
  );
});
