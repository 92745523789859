import React from 'react';
import notFoundImg from '../../../assets/images/not-found.png';
interface NotFoundProps {

}

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <div className="not-found-page">
        <div className="not-found-info-wrp">
            <span className="not-found-info">Error</span>
            <div className="not-found-img-block">
                <img src={notFoundImg} alt="" />
            </div>
            <span className="not-found-info">Page Not Found</span>
        </div>
       <button type="button" className="back-to-btn">
           <span className="back-to-btn-txt">
               Back To dashboard
           </span>
       </button>
    </div>
  );
};

export default NotFound;
