import React, { forwardRef } from 'react';

import './style.scss';

interface PopoverPopupProps {
	className?: string;
	align?: 'left' | 'right';
	children: React.ReactNode;
	direction?: 'to-bottom' | 'to-top' | 'absolute';
}

export const PopoverPopup = forwardRef<HTMLDivElement, PopoverPopupProps>(({
	children,
	align = 'left',
	className = '',
	direction = 'to-bottom'
}, ref) => {
  return (
    <div className={`popover ${direction} ${align} ${className}`} ref={ref}>
      <div className="popover-content">
        { children }
      </div>
    </div>
  );
});
