import {action, makeObservable, observable} from 'mobx';
import {RootStore} from './index';
import {HttpResponse} from '../api';
import {CountriesResponse} from '../models/api/countries';
import {DEFAULT_RESPONSE_LIST} from '../constants';
import {countriesController} from '../api/controllers/countries';

export class CountriesStore {
	rootStore: RootStore;
	countries = new HttpResponse<CountriesResponse>(DEFAULT_RESPONSE_LIST)

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeObservable(this, {
			countries: observable,
			setCountries: action.bound,
		})
	}

	public setCountries(value: HttpResponse<CountriesResponse>): void {
		this.countries = value
	}

	public async getCountries(): Promise<void> {
		this.setCountries(this.countries.fetching());
		const data = await countriesController.getCountries();
		this.setCountries(this.countries.fetched(data));
	}
}