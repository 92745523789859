import React, { useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Permission, Popover, PopoverOption } from '../../../shared';
import { RoutePaths, T } from '../../../../../constants';
import { Icons, UserType } from '../../../../../enums';
import { useTranslation } from '../../../../../hooks';

import './style.scss';

interface CreatePopoverProps {
  disableBTN?: boolean
}

export const CreatePopover: React.FC<CreatePopoverProps> = ({disableBTN}) => {

  
  const t = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const createPopoverOptions = useMemo(() => ([
    {
      label: T.PARTNER,
      icon: Icons.Partnership,
      navigateTo: RoutePaths.Partners,
      accessedUserTypes: [ UserType.SuperAdmin, UserType.Admin ]
    },
    {
      label: T.BUSINESS,
      icon: Icons.EditBusiness,
      navigateTo: RoutePaths.Businesses,
      accessedUserTypes: [ UserType.SuperAdmin, UserType.Admin ]
    },
    {
      label: T.MANAGER,
      icon: Icons.Manager,
      navigateTo: RoutePaths.Managers,
      accessedUserTypes: [ UserType.SuperAdmin, UserType.Admin, UserType.Partner ]
    },
    {
      label: T.BETSHOP,
      icon: Icons.Betshop,
      navigateTo: RoutePaths.Betshops,
      accessedUserTypes: []
    },
    {
      label: T.CASHIER,
      icon: Icons.Users,
      navigateTo: RoutePaths.Cashiers,
      accessedUserTypes: []
    },
  ]), []);

  const handleOptionClick = useCallback((path: string) => {
    setOpen(false);
    navigate(`${path}/add`);
  }, [navigate]);

  return (
    <div className="header-action">
      <Popover
        isOpen={!!disableBTN ? false : open}
        setIsOpen={setOpen}
        className="create-popover"
        opener={
          <Button
            text={t(T.CREATE)}
            icon={Icons.ArrowDown}
            disabled={disableBTN}
          />
        }
      >
        {
          createPopoverOptions.map(({ label, icon, navigateTo, accessedUserTypes }) => (
            <Permission
              key={navigateTo}
              accessedUserTypes={accessedUserTypes}
            >
              <PopoverOption
                icon={icon}
                label={t(label)}
                onClick={() => handleOptionClick(navigateTo)}
              />
            </Permission>
          ))
        }
      </Popover>
    </div>
  );
};
