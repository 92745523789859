import { UserSettingsStore } from "./user-settings-store";
import { TranslationsStore } from "./translations-store";
import { CurrenciesStore } from "./currencies-store";
import { ProvidersStore } from "./providers-store";
import { BetshopsStore } from "./betshops-store";
import { PartnersStore } from "./partners-store";
import { ReportsStore } from "./reports-store";
import { TicketsStore } from "./tickets-store";
import { WalletStore } from "./wallet-store";
import { UsersStore } from "./users-store";
import { AuthStore } from "./auth-store";
import { AppStore } from "./app-store";
import { CountriesStore } from "./countries-store";
import { BusinessStore } from "./business-store";
import { ConfigurationStore } from "./configuration-store";
import { GamesControlStore } from "./games-control-store";

export class RootStore {
  public configurationStore: ConfigurationStore;
  public userSettingsStore: UserSettingsStore;
  public translationsStore: TranslationsStore;
  public gamesControlStore: GamesControlStore;
  public currenciesStore: CurrenciesStore;
  public providersStore: ProvidersStore;
  public betshopsStore: BetshopsStore;
  public businessStore: BusinessStore;
  public partnerStore: PartnersStore;
  public reportsStore: ReportsStore;
  public ticketsStore: TicketsStore;
  public walletStore: WalletStore;
  public usersStore: UsersStore;
  public authStore: AuthStore;
  public appStore: AppStore;

  public countriesStore: CountriesStore;

  constructor() {
    this.userSettingsStore = new UserSettingsStore(this);
    this.translationsStore = new TranslationsStore(this);
    this.gamesControlStore = new GamesControlStore(this);
    this.currenciesStore = new CurrenciesStore(this);
    this.providersStore = new ProvidersStore(this);
    this.betshopsStore = new BetshopsStore(this);
    this.businessStore = new BusinessStore(this);
    this.partnerStore = new PartnersStore(this);
    this.reportsStore = new ReportsStore(this);
    this.ticketsStore = new TicketsStore(this);
    this.walletStore = new WalletStore(this);
    this.usersStore = new UsersStore(this);
    this.authStore = new AuthStore(this);
    this.appStore = new AppStore(this);
    this.countriesStore = new CountriesStore(this);
    this.configurationStore = new ConfigurationStore(this);
  }
}

export const rootStore = new RootStore();
