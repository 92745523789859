import React from 'react';

import HeadingLine from '../heading-line';

interface InputsSectionProps {
  title: string;
  children: React.ReactNode;
}

export const InputsSection: React.FC<InputsSectionProps> = ({ children, title }) => {
  return (
    <>
      <div className="settings-headline">
        <HeadingLine text={title} />
      </div>
      <form className="settings-inputs" autoComplete="off">
        { children }
      </form>
    </>
  );
}
