/* eslint-disable no-loop-func */
import { QueryModel } from '../models';
import { TypeChecker } from './type-checker';

export const combineQuery = (queries: QueryModel): string => {
    if (typeof queries === 'string') {
      return queries;
    } else {
      let query = '?';
      for (let key in queries) {
        if (key) {
          if (Array.isArray(queries[key])) {
            queries[key].forEach((val: any) => {
              query += `${key}=${encodeURIComponent(val)}&`;
            });
          } else {
            if(!TypeChecker.isUndefined(queries[key]) && !TypeChecker.isNull(queries[key]) && !TypeChecker.isEmptyString(queries[key])) {
              query += `${key}=${encodeURIComponent(queries[key])}&`;
            }
          }
        }
      }
      return query.slice(0, -1);
    }
};
