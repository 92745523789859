import React, { useMemo } from 'react';

import './style.scss';

interface GridProps {
  cols?: number;
  gridGap?: number;
  className?: string;
  children: React.ReactNode;
}

export const Grid: React.FC<GridProps> = ({
  children,
  cols = 1,
  gridGap = 1.6,
  className = ''
}) => {

  const style = useMemo(() => ({
    gridGap: `${gridGap}rem`,
    gridTemplateColumns: `repeat(${cols}, 1fr)`
  }), [cols, gridGap]);

  return (
    <div
      style={style}
      className={`grid-container ${className}`}
    >
      { children }
    </div>
  );
};
