import React, { useState, useRef, useCallback } from 'react';

import { useOutsideClick } from '../../../../hooks';

import './style.scss';
import { noop } from '../../../../constants';
import { PopoverPopup } from '../popover-popup';

interface PopoverProps {
	isOpen?: boolean;
	className?: string;
	opener?: React.ReactNode;
	align?: 'left' | 'right';
	children: React.ReactNode;
	direction?: 'to-bottom' | 'to-top';
	setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Popover: React.FC<PopoverProps> = ({
	opener,
	children,
	align = 'left',
	isOpen = false,
	className = '',
	setIsOpen = noop,
	direction = 'to-bottom'
}) => {

	const openerRef = useRef<HTMLDivElement | null>(null);
	const popoverRef = useRef<HTMLDivElement | null>(null);
	const [open, setOpen] = useState(false);

	const controlled = isOpen !== undefined;
	const show = controlled ? isOpen : open;
	const openerFn = controlled ? setIsOpen : setOpen; 
	

	const handleOutsideClick = useCallback(() => {
		openerFn(false);
	}, [openerFn]);

	const toggle = useCallback(() => {
		openerFn(prev => !prev);
	}, [openerFn]);

	useOutsideClick(handleOutsideClick, popoverRef, openerRef);

	return (
		<div className="popover-wr">
			<div
				ref={openerRef}
				onClick={toggle}
				className="popover-opener-wr"
			>
				{ opener }
			</div>
			{
				show && (
					<PopoverPopup
						align={align}
						ref={popoverRef}
						className={className}
						direction={direction}
					>
						{ children }
					</PopoverPopup>
				)
			}
		</div>
	);
};
