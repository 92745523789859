import { EditUserSettingsData, UserSettings } from '../../../models';
import { BaseApiService } from '../../base-api-service';
import { ApiVersions } from '../../../enums';
import { env } from '../../../environment';

class UserSettingsController extends BaseApiService {

  public async getUserSettings(): Promise<UserSettings> {
    const url = `${this.url}/api/${ApiVersions.V1}/userSettings`;
    return await this.http.get(url);
  }

  public async editUserSettings(data: EditUserSettingsData): Promise<UserSettings> {
    const url = `${this.url}/api/${ApiVersions.V1}/userSettings`;
    return await this.http.put(url, data);
  }

}

export const userSettingsController = new UserSettingsController({ url: env.httpUrl });
