import React, { memo, useMemo, useState } from "react";
import { T } from "../../../../constants";
import { Checkbox } from "../../../components/shared";
import { Icons } from "../../../../enums";
import { useTranslation } from "../../../../hooks";
import { betShops } from "../../../../models/api/games-control";
import Input from "../../../components/shared/input";

interface TransferListSectionProps {
  title: string;
  list: betShops[];
  selectedItems: any;
  onChange: (value: any) => void;
}

const TransferListSection: React.FC<TransferListSectionProps> = ({
  list,
  title,
  onChange,
  selectedItems,
}) => {
  const t = useTranslation();
  const [searchValue, setSearchValue] = useState("");

  const handleSelectAll = (isChecked: boolean) => {
    const selectedData: any = {};
    if (isChecked) {
      data.forEach(
        (item: { betshopId: string | number }) =>
          (selectedData[item.betshopId] = true),
      );
    }
    onChange(isChecked ? selectedData : {});
  };

  const handleChange = (value: number, isChecked: boolean) => {
    onChange({ ...selectedItems, [value]: isChecked });
  };

  const data = useMemo(() => {
    if (searchValue) {
      return list.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
    return list;
  }, [list, searchValue]);

  return (
    <div className="transfer-list">
      <div className="transfer-list-header">
        <span className="transfer-list-header-title">{t(title)}</span>
      </div>
      <div className="transfer-list-body">
        <div className="transfer-item-search">
          <Input
            label={t(T.SEARCH)}
            icon={Icons.Search}
            placeholder={t(T.SEARCH)}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </div>

        {data.length ? (
          <div className="transfer-list-content">
            <div className="transfer-list-content-item select-all">
              <Checkbox
                label={t(T.ALL)}
                checked={
                  Object.values(selectedItems).length === list.length &&
                  Object.values(selectedItems).every((item) => item)
                }
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
            </div>
            <div className="transfer-list-content-inner">
              {data.map((item) => (
                <div className="transfer-list-content-item">
                  <Checkbox
                    label={item.name}
                    checked={selectedItems[item.betshopId]}
                    onChange={(e) =>
                      handleChange(item.betshopId, e.target.checked)
                    }
                  />
                  <i className="icon-indicator indicator-icon" />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="transfer-list-empty-state">
            <span>{t(T.LIST_EMPTY)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(TransferListSection);
