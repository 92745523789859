import {T} from '../constants';

export const isValid = (value: string, regex: any, text: string) => {
	let textMessage = '';
	if (value.length > 50) {
		textMessage = T.LENGTH_VALIDATION
	} else if (!regex.test(value)) {
		textMessage = text
	}

	return textMessage
}

export const checkValidations = ( regex: RegExp, text: string) => {
	return regex.test(text)
}