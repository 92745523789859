import { T } from './translation-keys';

export const MONTHS = [
  T.JANUARY,
  T.FEBRUARY,
  T.MARCH,
  T.APRIL,
  T.MAY,
  T.JUNE,
  T.JULY,
  T.AUGUST,
  T.SEPTEMBER,
  T.OCTOBER,
  T.NOVEMBER,
  T.DECEMBER
];

export const WEEK_DAYS = [
  'Mo',
  'Tu',
  'We',
  'Th',
  'Fr',
  'Sa',
  'Su',
  // T.MONDAY_SHORT,
  // T.TUESDAY_SHORT,
  // T.WEDNESDAY_SHORT,
  // T.THURSDAY_SHORT,
  // T.FRIDAY_SHORT,
  // T.SATURDAY_SHORT,
  // T.SUNDAY_SHORT
];

export const DEFAULT_TIME_START_DATE = '00:00:00';
 export const DEFAULT_TIME_END_DATE = '23:59:59';