import {
  GetTicketReportRequest,
  GetUserReportRequest,
  TicketReportResponse,
  UserReportResponse,
} from '../../../models';
import { BaseApiService } from '../../base-api-service';
import { ApiVersions } from '../../../enums';
import { env } from '../../../environment';
import { http } from '../../../services';

class ReportsController extends BaseApiService {

  public async getUserReport(params?: GetUserReportRequest): Promise<UserReportResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/report/user`;
    return await http.get(url, { params });
  }

  public async getTicketReport(params?: GetTicketReportRequest): Promise<TicketReportResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/report/bet`;
    return await http.get(url, { params });
  }

}

export const reportsController = new ReportsController({ url: env.httpUrl });
