import { LimitOrBalanceUpdateData, WalletResponse } from '../../../models';
import { BaseApiService } from '../../base-api-service';
import { ApiVersions } from '../../../enums';
import { env } from '../../../environment';

class WalletController extends BaseApiService {

  public async getWallet(userId: number): Promise<WalletResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/walletApi/${userId}`;
    return await this.http.get(url);
  }

  public async updateLimit(userId: number, data: LimitOrBalanceUpdateData): Promise<WalletResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/walletApi/limit-add/${userId}`;
    return await this.http.put(url, data);
  }

  public async removeLimit(userId: number, data: LimitOrBalanceUpdateData): Promise<WalletResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/walletApi/limit-remove/${userId}`;
    return await this.http.put(url, data);
  }

  public async updateBalance(userId: number, data: LimitOrBalanceUpdateData): Promise<WalletResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/walletApi/balance-transfer/${userId}`;
    return await this.http.put(url, data);
  }

  public async removeBalance(userId: number, data: LimitOrBalanceUpdateData): Promise<WalletResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/walletApi/balance-take/${userId}`;
    return await this.http.put(url, data);
  }

}

export const walletController = new WalletController({ url: env.httpUrl });
