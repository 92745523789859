import { NavigationItem } from "../models";
import { RoutePaths } from "./route-paths";
import { Icons, UserType } from "../enums";
import { T } from "./translation-keys";

export const NAVIGATION_ITEMS: Array<NavigationItem> = [
  {
    label: T.DASHBOARD,
    path: RoutePaths.Dashboard,
    icon: Icons.Dashboard,
  },
  {
    label: T.BET_HISTORY,
    path: RoutePaths.BetHistory,
    icon: Icons.BetHistory,
  },
  {
    label: T.BUSINESSES,
    path: RoutePaths.Businesses,
    icon: Icons.EditBusiness,
    accessedUserTypes: [UserType.Admin, UserType.SuperAdmin],
  },
  {
    label: T.BUSINESS_SETTINGS,
    path: RoutePaths.BusinessSettings,
    icon: Icons.BusinessSettings,
    accessedUserTypes: [UserType.Partner],
    redirectById: true,
  },
  {
    label: T.PARTNERS,
    path: RoutePaths.Partners,
    icon: Icons.Partnership,
    accessedUserTypes: [UserType.SuperAdmin, UserType.Admin],
  },
  {
    label: T.BETSHOPS,
    path: RoutePaths.Betshops,
    icon: Icons.Betshop,
  },
  {
    label: T.MANAGERS,
    path: RoutePaths.Managers,
    icon: Icons.Manager,
    accessedUserTypes: [UserType.SuperAdmin, UserType.Admin, UserType.Partner],
  },
  {
    label: T.CASHIERS,
    path: RoutePaths.Cashiers,
    icon: Icons.Users,
  },
  {
    label: T.GAMES_CONTROL,
    path: RoutePaths.GameControl,
    icon: Icons.GamesControl,
  },
  // {
  //   label: T.TRANSACTION_HISTORY,
  //   path: RoutePaths.Transactions,
  //   icon: Icons.TransactionHistory
  // },
  {
    label: T.CONFIGURATIONS,
    path: RoutePaths.Configurations,
    icon: Icons.Configurations,
    accessedUserTypes: [UserType.SuperAdmin, UserType.Admin, UserType.Partner],
  },
];
