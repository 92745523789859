import { action, makeObservable, observable } from 'mobx';

import { RootStore } from './index';
import {ConfigurationsRequest, ConfigurationsUpdateData} from '../models/api/configurations';
import {configurationsController} from '../api/controllers/configurations';
import {HttpResponse} from '../api';
import {TicketViewState} from '../models';

export class ConfigurationStore {

  rootStore: RootStore;

  public configurations = new HttpResponse<ConfigurationsRequest>({} as ConfigurationsRequest);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      configurations: observable,
      setConfiguration: action.bound,
    })
  }

  public setConfiguration(value: HttpResponse<ConfigurationsRequest>): void {
    this.configurations = value
  }

  public async getConfigurationsById(params?: Object): Promise<ConfigurationsRequest> {
    this.setConfiguration(this.configurations.fetching());
    const data = await configurationsController.getConfigurations(params);
    this.setConfiguration(this.configurations.fetched(data));

    return data
  }

  public async updateConfigurations(data: TicketViewState) {
    return  configurationsController.updateConfigurations(data) ;
  }
}