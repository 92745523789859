import React, { useState, useCallback } from 'react';

import { useTranslation } from '../../../../hooks';
import { DialogModal } from '../dialog-modal';
import { Icons } from '../../../../enums';
import { T } from '../../../../constants';

interface ConfirmActionProps {
  icon?: Icons;
  description: string;
  iconColorHex?: string;
  onConfirm: () => void;
  children: React.ReactNode;
}

export const ConfirmAction: React.FC<ConfirmActionProps> = ({
  children,
  onConfirm,
  description,
  icon = Icons.Warning,
  iconColorHex = '#ff8c00'
}) => {

  const t = useTranslation();
  const [ isDialogModalOpen, setIsDialogModalOpen ] = useState(false);

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    setIsDialogModalOpen(false);
  }, [onConfirm , setIsDialogModalOpen]);

  return (
    <>
      <div onClick={() => setIsDialogModalOpen(true)}>
        {children}
      </div>
      {
        isDialogModalOpen && (
          <DialogModal
            icon={icon}
            cancelText={t(T.CANCEL)}
            description={description}
            onConfirm={handleConfirm}
            confirmText={t(T.CONFIRM)}
            iconColorHex={iconColorHex}
            onClose={() => setIsDialogModalOpen(false)}
          />
        )
      }
    </>
  );
};
