
import {TableColumnTitle} from './table-column-title';
import {TableColumn} from '../models';
import {TableColumnType} from '../enums';

interface TableColumnsRowProps {
	columns: Array<TableColumn>;
	sortData: (dataKey: string, reverse: boolean, type?: TableColumnType) => void
}

export const TableColumnsRow: React.FC<TableColumnsRowProps> = ({
																																	columns,
																																	sortData,
																																}) => {

	return (
		<div className="table-top">
			<div className="table-row header-row">
				<div className="table-row-cells">
					{
						columns.map(({title, dataKey, hideRow, type}) => (
							!hideRow ? <TableColumnTitle
								key={dataKey}
								dataKey={dataKey}
								name={title}
								type={type}
								onClick={sortData}
							/> : null
						))
					}
				</div>
			</div>
		</div>
	);
};
