import React from 'react';
import {useTranslation} from '../../../../../../hooks';
import {T} from '../../../../../../constants';

interface RowsCountProps {
  skip: number;
  rowsCount: number;
  pageRowsCount: number;
}

export const RowsCount: React.FC<RowsCountProps> = ({
  skip,
  rowsCount,
  pageRowsCount
}) => {
  const t = useTranslation()
  return (
    <p className="table-content-count-info">
      <span>{skip + 1}-{skip + pageRowsCount}</span> {t(T.OF)} <span>{rowsCount}</span>
    </p>
  );
};
