import React from 'react';

import { BreadcrumbItemData } from '../models';
import { Icons } from '../../../../../enums';
import { Icon } from '../../icon';

interface BreadcrumbItemProps extends BreadcrumbItemData {
  showArrow: boolean;
}

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  name,
  active,
  onClick,
  showArrow
}) => {
  return (
    <>
      {
        showArrow && (
          <Icon
            size="xxs"
            colorHex="#797a7b"
            type={Icons.ArrowRight}
            className="breadcrumb-arrow"
          />
        )
      }
      <div
        onClick={onClick}
        className={`breadcrumb ${active ? 'active' : ''}`}
      >
        <span className="breadcrumb-txt">
          {name}
        </span>
      </div>

    </>
  );
};
