import React from 'react';

import { useTranslation } from '../../../../hooks';
import { Icons } from '../../../../enums';
import { T } from '../../../../constants';
import { Button } from '../button';
import { Modal } from '../modal';
import { Icon } from '../icon';

interface DialogModalProps {
  icon: Icons;
  text?: string;
  title?: string;
  onClose: () => void;
  cancelText?: string;
  description?: string;
  iconColorHex: string;
  confirmText?: string;
  onConfirm: () => void;
}

export const DialogModal: React.FC<DialogModalProps> = ({
  text,
  icon,
  onClose,
  onConfirm,
  title = '',
  description,
  iconColorHex,
  cancelText = T.CANCEL,
  confirmText = T.CONFIRM,
}) => {

  const t = useTranslation();

  return (
    <Modal
      title={title}
      onClose={onClose}
    >
      <div className="small-popup-container">
        <div className="small-popup-inner">
          <Icon
            wrap
            size="lg"
            type={icon}
            colorHex={iconColorHex}
          />
          <div className="attention-main-text">
            <p className="head-text">
              {text}
            </p>
            {
              description && (
                <p className="small-pp-main-text">
                  {description}
                </p>
              )
            }
          </div>
          <div className="popup-button-case-edit-balance">
            <div className="button-inner-case">
              <Button
                onClick={onClose}
                text={t(cancelText)}
                appearance="secondary"
              />
            </div>
            <div className="button-inner-case">
              <Button
                onClick={onConfirm}
                text={t(confirmText)}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
