import { observer } from 'mobx-react-lite';

import { LoginForm } from './login-form';
import { Welcome } from './welcome';
import React from 'react';

interface LoginProps {
  children?: React.ReactNode;
}

const Login: React.FC<LoginProps> = observer(({children}) => {
  return (
    <div className="sign-in-wrapper">
      <div className="sign-in-space">
        <Welcome />
        {children}
      </div>
    </div>
    // Open comment to see Token expired page
    // <TokenExpired />
  );
});

export default Login;
