import React from "react";

import { DropdownOptionValue } from "../../models";
import { Checkbox } from "../../../checkbox";
import { Icon } from "../../../icon";

interface DropdownOptionProps {
  selected: boolean;
  disabled?: boolean;
  label: string | number;
  withCheckbox?: boolean;
  indeterminate?: boolean;
  value: DropdownOptionValue;
  onSelect: (value: DropdownOptionValue) => void;
}

export const DropdownOption: React.FC<DropdownOptionProps> = ({
  value,
  label,
  onSelect,
  selected,
  disabled,
  withCheckbox,
  indeterminate,
}) => {
  let icon;
  return (
    <div
      onClick={() => onSelect(value)}
      className={`menu-option default-option ${selected ? "active" : ""} ${disabled ? "disabled" : ""}`}
    >
      <div className="menu-content">
        {withCheckbox && (
          <div>
            <Checkbox checked={selected} indeterminate={indeterminate} />
          </div>
        )}
        <p className="checkbox-label">{label}</p>
        {/* <div className="checkbox-wrap">*/}
        {/*  <div className="checkbox-row">*/}
        {/*    <div className="checkbox">*/}
        {/*      <input type="checkbox" />*/}
        {/*      <span className={'checkbox-checkmark'} />*/}
        {/*    </div>*/}
        {/*    <p className="checkbox-label">{label}</p>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
