import {memo} from 'react';

import {TableRowAction} from '../../models';
import {Icon} from '../../../icon';

interface TableRowProps {
	onClick?: () => void;
	children: React.ReactNode;
	actions?: Array<TableRowAction>;
	id?: number;
	setOpenRow?: () => void
}

const TableRow: React.FC<TableRowProps> = ({
																						 onClick,
																						 actions,
																						 children,
																					 }) => {

	return (
		<div className="table-row" onClick={onClick}>
			<div className="table-row-inner">
				<div className="table-row-cells">
					{children}
				</div>
				{
					actions?.length ? (
						<div className="table-row-actions-panel">
							{
								actions.map(({icon, onClick}) => (
									<Icon
										key={icon}
										type={icon}
										onClick={onClick}
										className="table-row-action"
									/>
								))
							}
						</div>
					) : null
				}
			</div>
		</div>
	);
};

export default memo(TableRow)