import { makeObservable, observable, action } from 'mobx';

import { GetLanguagesRequest, GetTranslationsRequest, Language, LanguagesResponse, Translations } from '../models';
import { HttpResponse, translationsController } from '../api';
import { DEFAULT_RESPONSE_LIST } from '../constants';
import { getCurrentLanguageCode } from '../helpers';
import { RootStore } from '.';

export class TranslationsStore {
  
  private rootStore?: RootStore;

  public translations = new HttpResponse<Translations>({});

  public languages = new HttpResponse<LanguagesResponse>(DEFAULT_RESPONSE_LIST);

  public selectedLanguageCode = getCurrentLanguageCode();

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      translations: observable,
      languages: observable,
      selectedLanguageCode: observable,
      setTranslations: action.bound,
      setLanguages: action.bound,
      setSelectedLanguageCode: action.bound
    });
  }

  public setSelectedLanguageCode(value: string): void {
    this.selectedLanguageCode = value;
  }

  public setTranslations(value: HttpResponse<Translations>): void {
    this.translations = value;
  }

  public setLanguages(value: HttpResponse<LanguagesResponse>): void {
    this.languages = value;
  }

  public async getTranslations(params?: GetTranslationsRequest): Promise<void> {
    this.setTranslations(this.translations.fetching());
    const data = await translationsController.getTranslations(params);
    this.setTranslations(this.translations.fetched(data));
  }

  public async getLanguages(params?: GetLanguagesRequest): Promise<Array<Language>> {
    this.setLanguages(this.languages.fetching());
    const data = await translationsController.getLanguages(params);
    this.setLanguages(this.languages.fetched(data));
    return data.items;
  }

  public translate(alias: string): string {
    return this.translations.data[alias] || alias;
  }

}
