import { ResponseList, HTTPResponse } from '../models';
import { T } from './translation-keys';

export const DEFAULT_RESPONSE_LIST: ResponseList<any> = {
  count: 0,
  items: []
};

export const DEFAULT_ERROR_RESPONSE: HTTPResponse<null> = {
  result: null,
  errorMessage: T.SOMETHING_WENT_WRONG
};

export const noop = () => void 0;

export const BEARER = 'Bearer';

export const DEFAULT_TAKE = 10;

export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT_WITH_HOURS = 'DD.MM.YYYY HH:mm';

export const DATE_FORMAT_LONG = 'DD/MM/YYYY HH:mm';

export const DATE_FORMAT_BACK = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_BACK_WITHOUT_HORSE = 'YYYY/MM/DD';

export const TABLE_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
