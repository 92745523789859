import React from 'react';

import './style.scss';

interface LoaderProps {
  absolute?: boolean;
  className?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  className = '',
  absolute = false
}) => {
  return (
    <div className={`loader-wr ${className} ${absolute ? 'absolute' : ''}`}>
      <div className="loader">
        {/*<svg className="loader-layers" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*  <path className="loader-layer" d="M73.4583 41C73.4583 45.2625 72.6188 49.4832 70.9876 53.4213C69.3564 57.3593 66.9655 60.9375 63.9515 63.9515C60.9375 66.9655 57.3593 69.3564 53.4213 70.9876C49.4832 72.6188 45.2625 73.4583 41 73.4583C36.7375 73.4583 32.5168 72.6188 28.5787 70.9876C24.6407 69.3564 21.0625 66.9655 18.0485 63.9515C15.0345 60.9375 12.6436 57.3593 11.0124 53.4213C9.38123 49.4832 8.54167 45.2625 8.54167 41" stroke="#202123" strokeOpacity="0.3" strokeWidth="6.83333" strokeLinecap="round" />*/}
        {/*  <path className="loader-layer" d="M20.5007 40.9987C20.5007 38.3066 21.0309 35.6409 22.0611 33.1537C23.0913 30.6665 24.6014 28.4066 26.505 26.503C28.4086 24.5994 30.6685 23.0894 33.1556 22.0592C35.6428 21.0289 38.3086 20.4987 41.0006 20.4987C43.6927 20.4987 46.3585 21.0289 48.8457 22.0592C51.3328 23.0894 53.5927 24.5994 55.4963 26.503C57.3999 28.4066 58.91 30.6665 59.9402 33.1537C60.9704 35.6409 61.5006 38.3066 61.5006 40.9987" stroke="#007FDB" strokeWidth="6.83333" strokeLinecap="round" />*/}
        {/*  <path className="loader-layer" d="M34.1667 41C34.1667 41.8974 34.3434 42.7859 34.6868 43.615C35.0302 44.4441 35.5336 45.1974 36.1681 45.8319C36.8026 46.4664 37.5559 46.9698 38.385 47.3132C39.2141 47.6566 40.1026 47.8333 41 47.8333C41.8974 47.8333 42.7859 47.6566 43.615 47.3132C44.4441 46.9698 45.1974 46.4664 45.8319 45.8319C46.4664 45.1974 46.9698 44.4441 47.3132 43.615C47.6566 42.7859 47.8333 41.8974 47.8333 41" stroke="#202123" strokeOpacity="0.3" strokeWidth="6.83333" strokeLinecap="round" />*/}
        {/*</svg>*/}
        <svg className="loader-layers" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className="loader-layer" d="M73.4583 41C73.4583 45.2625 72.6188 49.4832 70.9876 53.4213C69.3564 57.3593 66.9655 60.9375 63.9515 63.9515C60.9375 66.9655 57.3593 69.3564 53.4213 70.9876C49.4832 72.6188 45.2625 73.4583 41 73.4583C36.7375 73.4583 32.5168 72.6188 28.5787 70.9876C24.6407 69.3564 21.0625 66.9655 18.0485 63.9515C15.0345 60.9375 12.6436 57.3593 11.0124 53.4213C9.38123 49.4832 8.54167 45.2625 8.54167 41"  strokeWidth="6.83333" strokeLinecap="round" />
          <path className="loader-layer" d="M20.5007 40.9987C20.5007 38.3066 21.0309 35.6409 22.0611 33.1537C23.0913 30.6665 24.6014 28.4066 26.505 26.503C28.4086 24.5994 30.6685 23.0894 33.1556 22.0592C35.6428 21.0289 38.3086 20.4987 41.0006 20.4987C43.6927 20.4987 46.3585 21.0289 48.8457 22.0592C51.3328 23.0894 53.5927 24.5994 55.4963 26.503C57.3999 28.4066 58.91 30.6665 59.9402 33.1537C60.9704 35.6409 61.5006 38.3066 61.5006 40.9987" strokeWidth="6.83333" strokeLinecap="round" />
          <path className="loader-layer" d="M34.1667 41C34.1667 41.8974 34.3434 42.7859 34.6868 43.615C35.0302 44.4441 35.5336 45.1974 36.1681 45.8319C36.8026 46.4664 37.5559 46.9698 38.385 47.3132C39.2141 47.6566 40.1026 47.8333 41 47.8333C41.8974 47.8333 42.7859 47.6566 43.615 47.3132C44.4441 46.9698 45.1974 46.4664 45.8319 45.8319C46.4664 45.1974 46.9698 44.4441 47.3132 43.615C47.6566 42.7859 47.8333 41.8974 47.8333 41" strokeWidth="6.83333" strokeLinecap="round" />
        </svg>
      </div>
    </div>
  );
};
