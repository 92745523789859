import { LocalStorageHelper } from '../helpers';
import { LocalStorageKeys } from '../constants';
import {Theme} from '../enums/theme';

export const getCurrentTheme = (): Theme => {
  const viewMode = LocalStorageHelper.getItem(LocalStorageKeys.ViewMode);
  if (!viewMode) {
    return Theme.Light;
  }
  return viewMode as Theme;
};
